import axios from "axios";
import {
  ESD_API_DOMAIN,
  GET_SHORT_URL,
  GET_APP_PAGE,
  GET_DEAL,
  GET_SHOPPING_LIST,
  GET_SHOPPING_Detail,
  GET_SHOPPING_APPLIANCES_Detail_,
  GET_STORE_AFFILIATE,
  SEND_WEB_OTP,
  VERIFY_OTP,
  GET_POINTS,
  GET_COUPON,
  SSO_LOGIN,
  LOGOUT,
} from "../apiConfig";
import * as StringHelpers from "./helperService";
import { devPartnerList } from "../PartnerConfig/devPartnerList";
import { prodPartnerList } from "../PartnerConfig/prodPartnerList";

const getDefaultParams = () => {
  let partnerList;
  if (process.env.REACT_APP_PARTNER_CONFIG === "PRODUCTION") {
    partnerList = prodPartnerList;
  } else {
    partnerList = devPartnerList;
  }
  return {
    key: localStorage.getItem("key")
      ? localStorage.getItem("key")
      : Object.values(partnerList)[0]?.key,
    pid: localStorage.getItem("pid")
      ? localStorage.getItem("pid")
      : Object.values(partnerList)[0]?.pid,
    num: 30,
  };
};

const getDefaultParamsForOtp = () => {
  let partnerList;
  if (process.env.REACT_APP_PARTNER_CONFIG === "PRODUCTION") {
    partnerList = prodPartnerList;
  } else {
    partnerList = devPartnerList;
  }
  return {
    utm_medium: "default",
    dpi: 480,
    hash: "dXQstQ73wdA",
    otpid: "",
    uuid: "c6e5e556-8575-45e1-914d-964a2148b2fe",
    did: "all",
    msgid: "ade50ab7-f56e-428f-9d1c-54d7c9ef79c1",
    vc: 139,
    connection_type: "4G",
    key: localStorage.getItem("key")
      ? localStorage.getItem("key")
      : Object.values(partnerList)[0]?.key,
    pid: localStorage.getItem("pid")
      ? localStorage.getItem("pid")
      : Object.values(partnerList)[0]?.pid,
    version: 3,
    s_id: 1614235658037,
    suserid: "scusr-3a344de8-0a4e-4aa3-be1e-dabbb1dd9d6c",
    utm_source: "default",
    utm_campaign: "default",
  };
};

const getDefaultParamsForVerifyOTP = () => {
  let partnerList;
  if (process.env.REACT_APP_PARTNER_CONFIG === "PRODUCTION") {
    partnerList = prodPartnerList;
  } else {
    partnerList = devPartnerList;
  }
  return {
    dpi: 480,
    vc: 139,
    rid: "",
    key: localStorage.getItem("key")
      ? localStorage.getItem("key")
      : Object.values(partnerList)[0]?.key,
    pid: localStorage.getItem("pid")
      ? localStorage.getItem("pid")
      : Object.values(partnerList)[0]?.pid,
    version: 2,
    login_src: "webOTP",
  };
};

export function getAllDeals(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getDealsList(customParams: Object, body: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    ...(Object.entries(body).length !== 0 && { data: JSON.stringify(body) }),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getDealDetail(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_DEAL, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getSearchedData(customParams: Object, body: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    ...(Object.entries(body).length !== 0 && { data: JSON.stringify(body) }),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getAllCoupons(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}
export function getCouponDetail(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_COUPON, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getShoppingData(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getShoppingList(body: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_SHOPPING_LIST,
      finalrequestParams
    ),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(body),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}
export function getShoppingDetail(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_SHOPPING_Detail,
      finalrequestParams
    ),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}
export function getShoppingAppliancesDetail(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_SHOPPING_APPLIANCES_Detail_,
      finalrequestParams
    ),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}
export function getStoreAffiliateInfo(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_STORE_AFFILIATE,
      finalrequestParams
    ),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getShareUrl(body: any) {
  const finalrequestParams = {};
  const ApiParameter = {
    url: StringHelpers.getAPI(
      ESD_API_DOMAIN,
      GET_SHORT_URL,
      finalrequestParams
    ),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(body),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getStoreList(body: Object, data: object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...body };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_APP_PAGE, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(data),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function sendOTP(body: Object, data: object) {
  const defaultParams = getDefaultParamsForOtp();
  const finalrequestParams = { ...defaultParams, ...body };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, SEND_WEB_OTP, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(data),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function verifyOTP(body: Object, data: object) {
  const defaultParams = getDefaultParamsForVerifyOTP();
  const finalrequestParams = { ...defaultParams, ...body };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, VERIFY_OTP, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(data),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

//For mahabank intil now
export function ssoLogin(params: Object, payload: object) {
  const finalrequestParams = { ...params };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, SSO_LOGIN, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(payload),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

//For mahabank intil now
export function logout(params: Object, payload: object) {
  const finalrequestParams = { ...params };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, LOGOUT, finalrequestParams),
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    data: JSON.stringify(payload),
  };

  return axios(ApiParameter).then((response) => {
    return response;
  });
}

export function getPoints(customParams: Object) {
  const defaultParams = getDefaultParams();
  const finalrequestParams = { ...defaultParams, ...customParams };
  const ApiParameter = {
    url: StringHelpers.getAPI(ESD_API_DOMAIN, GET_POINTS, finalrequestParams),
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  return axios(ApiParameter).then((response) => {
    return response?.data;
  });
}