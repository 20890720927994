import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonBackButton,
  IonRow,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { Account } from "../../../components/Account/Account";

export const TermsAndConditionsUS: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-padding-horizontal ion-hide-sm-down">
        <IonGrid fixed>
          <IonToolbar>
            <IonTitle className="ion-no-padding font18 font600">
              Terms & Conditions
            </IonTitle>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonHeader className="ion-hide-sm-up">
        <IonGrid fixed>
          <IonToolbar>
            <IonRow>
              <IonBackButton
                className="ion-no-padding"
                icon={chevronBack}
              ></IonBackButton>
              <IonTitle className="ion-padding-horizontal font18 font600">
                Terms & Conditions
              </IonTitle>
            </IonRow>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonContent>
        <IonGrid fixed className="ion-padding-horizontal">
          <p className="font14 font400">
            Please read these Terms and Conditions ("Agreement") carefully as
            they govern your use of Scandid US services. By accessing or using
            our website (https://us.scandid.deals/) ("Scandid Properties"), you
            agree to be bound by this Agreement. If you do not agree with these
            terms, discontinue use immediately.
          </p>
          <h2 className="font16 font600">1. Changes to Terms and Conditions</h2>
          <h3 className="font15 font500 ion-padding-start">
            1.1 Modifications
          </h3>
          <p className="font14 font400 ion-padding-start">
            Scandid US reserves the right to update, modify, or remove any part
            of these Terms and Conditions at any time. Such changes take
            immediate effect upon notification via website updates, email, or
            other reasonable means.
          </p>
          <h3 className="font15 font500 ion-padding-start">
            1.2 Acceptance of Modifications
          </h3>
          <p className="font14 font400 ion-padding-start">
            Your continued use of Scandid Properties after modifications
            constitutes acceptance of the updated Terms and Conditions. If you
            disagree with any modifications, your sole remedy is to terminate
            your account.
          </p>

          <h2 className="font16 font600">2. Program Account</h2>
          <h3 className="font15 font500 ion-padding-start">2.1 Eligibility</h3>
          <p className="font14 font400 ion-padding-start">
            Our Programs are open to residents of the United States aged 18 and
            above. To create an account ("Account"), you must provide a valid
            email address and password. Each individual is limited to one
            Account.
          </p>
          <h3 className="font15 font500 ion-padding-start">2.2 Account Use</h3>
          <p className="font14 font400 ion-padding-start">
            You are responsible for maintaining the security of your Account and
            for all activities conducted under your login credentials.
          </p>

          <h2 className="font16 font600">3. Offers, Deals, and Commissions</h2>
          <h3 className="font15 font500 ion-padding-start">3.1 Deals Policy</h3>
          <p className="font14 font400 ion-padding-start">
            Scandid US provides access to exclusive deals, discounts, and
            commissions on eligible purchases made through our platform.
          </p>
          <h3 className="font15 font500 ion-padding-start">
            3.2 Partner Store Offers
          </h3>
          <p className="font14 font400 ion-padding-start">
            Offers and discounts displayed on Scandid US are provided by
            third-party merchants. Prices, discounts, and availability may
            change without notice. We are not responsible for merchant errors,
            inaccuracies, or failures to honor promotions.
          </p>
          <h3 className="font15 font500 ion-padding-start">
            3.3 Commission-Based Earnings
          </h3>
          <p className="font14 font400 ion-padding-start">
            Certain purchases through Scandid US may allow users to earn
            commissions based on specific promotions or partnership agreements.
            The eligibility and terms of such commissions are determined by the
            merchant and are subject to change.
          </p>

          <h2 className="font16 font600">4. Limitations of Liability</h2>
          <h3 className="font15 font500 ion-padding-start">
            4.1 No Guarantees on Offers
          </h3>
          <p className="font14 font400 ion-padding-start">
            Scandid US makes no guarantees regarding the availability, accuracy,
            or quality of deals and discounts provided by third-party merchants.
          </p>
          <h3 className="font15 font500 ion-padding-start">
            4.2 No Liability for Transactions
          </h3>
          <p className="font14 font400 ion-padding-start">
            All purchases made through Scandid US are directly between the user
            and the merchant. Scandid US does not process payments or handle
            refunds. Any disputes must be resolved with the respective merchant.
          </p>

          <h2 className="font16 font600">5. Prohibited Activities</h2>
          <p className="font14 font400 ion-padding-start">You agree not to:</p>
          <ul>
            <li>Use bots or automated means to access Scandid US.</li>
            <li>
              Manipulate deals, offers, or commissions through fraudulent
              activities.
            </li>
            <li>Misrepresent your identity or create multiple accounts.</li>
          </ul>

          <h2 className="font16 font600 ion-padding-start">
            6. Dispute Resolution
          </h2>
          <h3 className="font15 font500 ion-padding-start">
            6.1 Informal Resolution
          </h3>
          <p className="font14 font400 ion-padding-start">
            Before initiating legal action, you agree to attempt resolving any
            dispute informally by contacting our support team.
          </p>
          <h3 className="font15 font500 ion-padding-start">
            6.2 Arbitration Agreement
          </h3>
          <p className="font14 font400 ion-padding-start">
            All disputes shall be resolved through binding arbitration. By using
            Scandid US, you waive your right to a jury trial or class-action
            lawsuit.
          </p>

          <h2 className="font16 font600">7. Account Maintenance</h2>
          <h3 className="font15 font500 ion-padding-start">
            7.1 Updating Your Account
          </h3>
          <p className="font14 font400 ion-padding-start">
            You agree to keep your Account information current, complete, and
            accurate by periodically updating it through the Scandid Properties.
            You must be logged in and enter your password to make any changes.
            You will maintain the confidentiality of your Account information,
            including username and password. Any use of your account credentials
            is assumed to be your authorization. If there is a breach of
            security, you agree to change your password and notify Scandid.
          </p>
          <h3 className="font15 font500 ion-padding-start">
            7.2 Fraudulent Activity
          </h3>
          <p className="font14 font400 ion-padding-start">
            Scandid reserves the right to investigate any suspected abuse of the
            program, including fraudulent transactions, returns, or account
            manipulation. Accounts found to be in violation may have their
            rewards revoked, be suspended, or terminated. Scandid's decisions
            regarding fraud are final.
          </p>

          <h2 className="font16 font600">8. Receiving Communications</h2>
          <p className="font14 font400 ion-padding-start">
            8.1 By signing up, you agree to receive electronic communications
            regarding your account and special offers. These may include account
            updates, promotional emails, and transaction confirmations. You can
            opt out of certain communications under Scandid’s Privacy Policy.
          </p>

          <h2 className="font16 font600">9. Third-Party Platforms</h2>
          <p className="font14 font400 ion-padding-start">
            9.1 Scandid may allow third-party platforms like Google, Apple, or
            Facebook for account creation and sign-in. Using these platforms is
            subject to their respective terms and privacy policies.
          </p>

          <h2 className="font16 font600">10. Your Feedback</h2>
          <p className="font14 font400 ion-padding-start">
            10.1 You may be invited to provide feedback, comments, or
            suggestions about Scandid's Program. By submitting feedback, you
            grant Scandid and its affiliates the right to use, modify, and
            distribute your feedback for business purposes, including
            advertising and promotional efforts.
          </p>

          <h2 className="font16 font600">11. Community Standards</h2>
          <p className="font14 font400 ion-padding-start">
            11.1 By participating in the Program, you agree to maintain a
            positive, responsible, and respectful presence within the Scandid
            community. Members must refrain from inappropriate behavior,
            including offensive or harassing comments, and fraudulent
            activities. Violators may be suspended or removed from the Program.
          </p>

          <h2 className="font16 font600">12. Ownership</h2>
          <p className="font14 font400 ion-padding-start">
            12.1 All rights to the Scandid Program, website, content, and
            trademarks belong to Scandid or its licensors. You may not use
            Scandid’s trademarks or content without prior written consent.
          </p>

          <h2 className="font16 font600">13. Indemnification</h2>
          <p className="font14 font400 ion-padding-start">
            13.1 You agree to indemnify and hold harmless Scandid, our affiliate
            stores, as well as their respective officers, directors, employees,
            successors, agents, and affiliates, from any and all claims,
            damages, losses, and causes of action (including attorneys’ fees and
            court costs) arising out of or relating to your breach of this
            Agreement or for any materials in any form whatsoever that are
            provided by you (or through your username and/or password). You
            agree to cooperate fully as reasonably required in our defense
            and/or settlement of any claim. We reserve the right, in our
            reasonable discretion, to assume exclusive control over the defense
            and settlement of any matter subject to indemnification by you.
          </p>

          <h2 className="font16 font600">14. Warranty Disclaimer</h2>
          <p className="font14 font400 ion-padding-start">
            14.1 The program, content, and the company properties are provided
            "as-is" and without warranty of any kind, express or implied,
            including, without limitation, warranties of title, merchantability,
            fitness for a particular purpose, or non-infringement. we make no
            warranty as to the quality, accuracy, completeness, reliability, or
            validity of the programs, content, or the company properties.
            scandid does not warrant that the functionality of the company
            properties will be uninterrupted or error-free or that they will be
            free of viruses or other harmful components.
          </p>

          <h2 className="font16 font600">15. Limitation of Liability</h2>
          <p className="font14 font400 ion-padding-start">
            15.1 To the maximum extent permitted by applicable law, in no event
            shall scandid be liable for any special, incidental, consequential,
            statutory, exemplary, punitive, or other indirect damages or for any
            loss of profits, loss of data, or loss of use damages, even if it
            has been advised of the possibility of such damages. to the maximum
            extent permitted by applicable law, you agree that scandid’s maximum
            aggregate liability arising out of this agreement will not exceed
            fifty u.s. dollars ($50). this limitation shall apply to any and all
            liabilities or causes of action however alleged or arising,
            including negligence or breach of contract.
          </p>

          <h2 className="font16 font600">16. Termination or Suspension</h2>
          <p className="font14 font400 ion-padding-start">
            16.1 This Agreement is effective when accepted by you and will
            remain in effect until you or we terminate your Account. We may
            terminate this Agreement, your Account, and your use of or access to
            the Program at any time, for any reason. You agree that Scandid will
            not be liable for any modification, suspension, or termination of
            the Program or your access to any of the Scandid Properties.
          </p>

          <h2 className="font16 font600">17. General Provisions</h2>
          <p className="font14 font400 ion-padding-start">
            17.1 Entire Agreement These Terms and Conditions constitute the
            entire agreement between you and Scandid and govern your use of the
            Program or Scandid Properties, superseding any prior agreements
            between you and Scandid.
          </p>
          <p className="font14 font400 ion-padding-start">
            17.2 Choice of Law and Venue The validity, construction, and
            interpretation of this Agreement and the relationship between you
            and Scandid will be governed by the laws of the State of California
            without regard to its conflict of law provisions. The exclusive
            venue for any dispute shall be San Francisco County, California.
          </p>
          <p className="font14 font400 ion-padding-start">
            17.3 Waiver and Severability Any waiver or failure to enforce any
            provision of this Agreement on one occasion will not be deemed a
            waiver of any other provision on any other occasion. If any
            provision of this Agreement is held to be invalid, such invalidity
            shall not affect the remaining provisions.
          </p>
          <p className="font14 font400 ion-padding-start">
            17.4 Assignment You may not assign, transfer, or otherwise dispose
            of your rights and obligations under this Agreement without our
            prior written consent. Scandid has the right to transfer, assign, or
            otherwise dispose of these Terms and Conditions without your
            consent.
          </p>
        </IonGrid>
        <Account isHomePage={false} />
      </IonContent>
    </IonPage>
  );
};
