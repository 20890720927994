import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonItem,
  IonList,
  IonBackButton,
  IonRow,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { Account } from "../../../components/Account/Account";

export const FaqUs: React.FC = () => {
  const faqs = [
    {
      question: "What is Scandid, and how does it work?",
      answer:
        "Scandid is a smart price comparison platform that helps you find the best deals on a wide range of products by aggregating prices from major US e-commerce sites.",
    },
    {
      question: "Is Scandid free to use?",
      answer:
        "Yes! Scandid is completely free for all users. We help you save money without any hidden charges.",
    },
    {
      question: "How do I search for products on Scandid?",
      answer:
        "Simply enter the product name in the search bar, and our system will fetch the best deals from various online retailers instantly.",
    },
    {
      question: "Does Scandid sell products directly?",
      answer:
        "No, we do not sell products directly. We provide price comparisons and direct you to the retailer’s website for purchase.",
    },
    {
      question: "How does Scandid find the best prices?",
      answer:
        "Our AI-driven price comparison tool continuously scans and updates product prices from multiple online stores to ensure you get the lowest possible price.",
    },
    {
      question: "Can I track price drops on Scandid?",
      answer:
        "Yes! You can set price alerts, and we will notify you when the price of your desired product drops.",
    },
    {
      question: "Is Scandid available as a mobile app?",
      answer:
        "Yes! Our mobile app is available on both iOS and Android, making it easy to compare prices on the go.",
    },
    {
      question: "How can I filter my search results?",
      answer:
        "Use our advanced filters to sort products by price, brand, seller, reviews, and more.",
    },
    {
      question: "Do you provide cashback or discounts?",
      answer:
        "We highlight retailer discounts and exclusive offers, but cashback policies depend on the respective e-commerce platforms.",
    },
    {
      question: "How can I stay updated on the latest deals?",
      answer:
        "Subscribe to our newsletter or follow us on social media for the latest offers and shopping insights.",
    },
    {
      question: "Does Scandid support international shopping?",
      answer:
        "Currently, we focus on US-based retailers, but we are working on expanding our services globally.",
    },
    {
      question: "How do I report incorrect pricing or out-of-stock products?",
      answer:
        "If you notice discrepancies, please use the 'Report an Issue' button on the product page or email support@scandid.us.",
    },
    {
      question: "How secure is my data on Scandid?",
      answer:
        "We prioritize user privacy and do not store sensitive information. Our website follows strict security protocols.",
    },
    {
      question: "Can I create a wishlist on Scandid?",
      answer:
        "Yes, registered users can create and manage wishlists for their favorite products.",
    },
    {
      question: "How can I contact customer support?",
      answer:
        "You can reach our 24/7 support team via email at support@scandid.us or call us at +1-800-XXX-XXXX.",
    },
  ];

  return (
    <IonPage>
      <IonHeader className="ion-padding-horizontal ion-hide-sm-down">
        <IonGrid fixed>
          <IonToolbar>
            <IonTitle className="ion-no-padding font18 font600">
              Frequently Asked Questions (FAQ)
            </IonTitle>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonHeader className="ion-hide-sm-up">
        <IonGrid fixed>
          <IonToolbar>
            <IonRow>
              <IonBackButton
                className="ion-no-padding"
                icon={chevronBack}
              ></IonBackButton>
              <IonTitle className="ion-padding-horizontal font18 font600">
                Frequently Asked Questions (FAQ)
              </IonTitle>
            </IonRow>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonContent>
        <IonGrid fixed className="ion-padding-horizontal">
          <h1 className="font16 font600">
            Welcome to Scandid's FAQ page! Below, we've answered the most
            commonly asked questions to help you make the most of your shopping
            experience. If you have additional queries, feel free to reach out
            to our support team.
          </h1>
          <IonList>
            {faqs.map((faq, index) => (
              <IonItem key={index} className="ion-no-padding">
                <div>
                  <h2 className="font16 font600">{faq.question}</h2>
                  <p className="font14 font400">{faq.answer}</p>
                </div>
              </IonItem>
            ))}
          </IonList>
          <p className="font14 font400">
            For more information, visit our Contact Us page.
          </p>
        </IonGrid>
        <Account isHomePage={false} />
      </IonContent>
    </IonPage>
  );
};
