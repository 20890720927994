import { useState, useEffect, useRef } from "react";
import styles from "./HeaderLogoPopoverComponent.module.scss";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonIcon,
  IonLabel,
  IonList,
  IonNote,
  IonPopover,
} from "@ionic/react";
import { AccountPopoverContent } from "../AccountPopoverContent/AccountPopoverContent";
import { personCircle } from "ionicons/icons";
import { usePartner } from "../../Contexts/PartnerContext";
import { scandidPids } from "../../utils/pidHelper";

export const HeaderLogoPopoverComponent = ({ fetchDatafn, countries }: any) => {
  const { imgLogoSrc, showLogin, partnerPid } = usePartner();
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [popoverState, setShowPopover] = useState<any>({
    showPopover: false,
    event: undefined,
  });
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        setIsCardVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cardRef]);

  useEffect(() => {
    const isCountryCode = Object.keys(
      JSON.parse(localStorage.getItem("country") || "{}")
    );
    if (isCountryCode.length === 0) {
      if (countries) setSelectedCountry(countries[0]);
    } else {
      setSelectedCountry(JSON.parse(localStorage.getItem("country") || "{}"));
    }
  }, [countries]);

  const closePopOver = () => {
    setShowPopover({
      showPopover: false,
      event: undefined,
    });
  };

  const isPidInArray = (pid: any) => {
    return scandidPids.includes(pid);
  };

  const handlePopoverClick = (e: React.MouseEvent) => {
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  };

  const changeCountryCode = (item: any) => {
    console.log(item);
    setIsCardVisible(false);
    setSelectedCountry(item);
    localStorage.setItem("country", JSON.stringify(item));
    localStorage.setItem("pid", item.pid);
    fetchDatafn();
  };

  return (
    <div className={styles.headerLogoPopover}>
      <div className={`${styles.logoProfileBox} ion-hide-sm-up`}>
        <div className={styles.blankDiv}></div>
        <div className={showLogin ? styles.mshopLogoLogin : styles.mshopLogo}>
          <img src={imgLogoSrc} alt="logo" />
        </div>
        <div className={`${styles.blogLoginBox}`}>
          {selectedCountry && (
            <IonButton
              onClick={() => setIsCardVisible(!isCardVisible)}
              className={`${styles.countryButton}`}
            >
              <IonNote
                slot="start"
                dangerouslySetInnerHTML={{
                  __html: `${selectedCountry?.country?.emoji}`,
                }}
                className={styles.countryFlags}
              />
              <IonLabel>{` ${selectedCountry?.country?.iso2}`}</IonLabel>
            </IonButton>
          )}
          {isPidInArray(partnerPid) && (
            <IonButton
              className={`${styles.active} ${styles.underline}`}
              onClick={() => {
                try {
                  window.open("https://blog.scandid.deals", "_blank");
                } catch (error) {
                  console.error("Error opening link:", error);
                }
              }}
            >
              Blog
            </IonButton>
          )}
          {showLogin && (
            <IonButtons slot="end">
              <IonButton onClick={handlePopoverClick}>
                <IonIcon slot="icon-only" icon={personCircle} />
              </IonButton>
            </IonButtons>
          )}
        </div>
      </div>
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
        className={`${styles.popOver}`}
      >
        <AccountPopoverContent closePopOver={closePopOver} />
      </IonPopover>
      {isCardVisible && countries && countries.length > 1 && (
        <div
          ref={cardRef}
          className={`${styles.cardContainer} ${
            isCardVisible ? `${styles.showCard}` : ""
          }`}
        >
          <IonCard>
            <IonList className="ion-no-padding">
              {countries?.map((item: any) => {
                return (
                  <div
                    className={`${styles.countryListItem} pad10`}
                    onClick={() => changeCountryCode(item)}
                  >
                    <IonLabel className="primary-title-color marr10 font12 font500">
                      {item?.country?.emoji}
                    </IonLabel>
                    <IonLabel className="primary-title-color font14 font500">
                      {item?.country?.name}
                    </IonLabel>
                  </div>
                );
              })}
            </IonList>
          </IonCard>
        </div>
      )}
    </div>
  );
};
