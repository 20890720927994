import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonBackButton,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { Account } from "../../../components/Account/Account";

export const ContactUsUk: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-padding-horizontal ion-hide-sm-down">
        <IonGrid fixed>
          <IonToolbar>
            <IonTitle className="ion-no-padding font18 font600">
              Contact Us
            </IonTitle>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonHeader className="ion-hide-sm-up">
        <IonGrid fixed>
          <IonToolbar>
            <IonRow>
              <IonBackButton
                className="ion-no-padding"
                icon={chevronBack}
              ></IonBackButton>
              <IonTitle className="ion-padding-horizontal font18 font600">
                Contact Us
              </IonTitle>
            </IonRow>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonContent>
        <IonGrid fixed className="h90 ion-padding-horizontal">
          <IonList>
            <IonItem class="ion-no-padding">
              <div>
                <h2 className="font16 font600">We're Here to Help!</h2>
                <p className="font14 font400">
                  At Scandid, customer satisfaction is our top priority. Whether
                  you have questions about our price comparison tools, need
                  support with an order, or want to share feedback, we're here
                  to assist you.
                </p>
              </div>
            </IonItem>
            <h2 className="font16 font600">How to Reach Us</h2>
            <IonItem class="ion-no-padding">
              <div>
                <h2 className="font16 font600">Customer Support</h2>
                <p className="font14 font400">
                  For any inquiries, product-related questions, or technical
                  issues, our support team is available 24/7.
                </p>
                <p className="font14 font400">
                  Email:{" "}
                  <a href="mailto:support@scandid.us">support@scandid.us</a>
                </p>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <div>
                <h2 className="font16 font600">
                  Business & Partnership Inquiries
                </h2>
                <p className="font14 font400">
                  Interested in partnering with Scandid? We welcome
                  collaborations with retailers, brands, and technology
                  providers.
                </p>
                <p className="font14 font400">
                  Email: <a href="mailto:info@scandid.us">info@scandid.us</a>
                </p>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <div>
                <h2 className="font16 font600">
                  Frequently Asked Questions (FAQ)
                </h2>
                <p className="font14 font400">
                  For quick answers, visit our FAQ Page covering common queries
                  about orders, payments, and services.
                </p>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <div>
                <h2 className="font16 font600">Office Hours</h2>
                <p className="font14 font400">
                  Monday - Friday: 9 AM - 6 PM (PST)
                </p>
                <p className="font14 font400">Saturday - Sunday: Closed</p>
              </div>
            </IonItem>
            <IonItem class="ion-no-padding">
              <div>
                <h2 className="font16 font600">Send Us a Message</h2>
                <p className="font14 font400">
                  Use our online contact form to reach out, and our team will
                  respond promptly. Click here to submit your query.
                </p>
                <p className="font14 font400">
                  We look forward to assisting you!
                </p>
              </div>
            </IonItem>
          </IonList>
        </IonGrid>
        <Account isHomePage={false} />
      </IonContent>
    </IonPage>
  );
};
