import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
} from "@ionic/react";
import { wallet, logOut, logIn } from "ionicons/icons";
import styles from "./AccountPopoverContent.module.scss";
import { useHistory } from "react-router";
import { ssoPids } from "../../utils/pidHelper";
import { usePartner } from "../../Contexts/PartnerContext";
import { logout } from "../../utils/getApiData";
import { useState } from "react";

export const AccountPopoverContent = ({ closePopOver }: any) => {
  const history = useHistory();
  const { partnerPid, clientData } = usePartner();
  const [showLoading, setShowLoading] = useState(false);
  const isAuthenticated = () => {
    const profileData: any = JSON.parse(
      localStorage.getItem("profile") || "{}"
    );
    if (profileData?.token) {
      return true;
    }
    return false;
  };

  const logoutUser = async () => {
    if (ssoPids.includes(partnerPid)) {
      const usersession = localStorage.getItem("usersession");
      localStorage.clear();
      closePopOver();
      window.location.href = `${clientData?.logOutRedirectUrl}/${usersession}`;
    } else {
      localStorage.clear();
      closePopOver();
      window.location.reload();
    }
  };
  return (
    <IonContent className={`${styles.accountPopover}`}>
      {isAuthenticated() ? (
        <div>
          <IonItem
            button
            detail={false}
            onClick={() => {
              history.replace("/cashback");
              closePopOver();
            }}
            lines="none"
          >
            <IonIcon slot="start" icon={wallet} color="success" />
            <IonLabel className="ion-no-border">Cashback</IonLabel>
          </IonItem>
          <IonItem
            button
            detail={false}
            onClick={() => {
              logoutUser();
            }}
            lines="none"
          >
            <IonIcon slot="start" icon={logOut} color="danger" />
            <IonLabel>Logout</IonLabel>
          </IonItem>
        </div>
      ) : (
        <div>
          <IonItem
            button
            detail={false}
            onClick={() => {
              history.replace("/login");
              closePopOver();
            }}
            lines="none"
          >
            <IonIcon slot="start" icon={logIn} color="success" />
            <IonLabel className="ion-no-border">Login</IonLabel>
          </IonItem>
        </div>
      )}
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Logging Out ..."}
      />
    </IonContent>
  );
};
