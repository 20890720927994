import {
  IonHeader,
  IonGrid,
  IonRow,
  IonButton,
  IonImg,
  IonSearchbar,
  IonButtons,
  IonIcon,
  IonPopover,
  IonLabel,
  IonNote,
  IonCard,
  IonList,
} from "@ionic/react";
import styles from "./Header.module.scss";
import { useHistory } from "react-router";
import { googleAnalyticsSearchTerm } from "../../utils/reactGa";
import { usePartner } from "../../Contexts/PartnerContext";
import { useState, useEffect, useRef } from "react";
import { personCircle } from "ionicons/icons";
import { AccountPopoverContent } from "../AccountPopoverContent/AccountPopoverContent";
import { scandidPids } from "../../utils/pidHelper";

export const Header = ({ data, searchBar, fetchDatafn, countries }: any) => {
  const history = useHistory();
  const { webLogoSrc, showLogin, partnerPid } = usePartner();
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [popoverState, setShowPopover] = useState<any>({
    showPopover: false,
    event: undefined,
  });
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        setIsCardVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cardRef]);

  useEffect(() => {
    const isCountryCode = Object.keys(
      JSON.parse(localStorage.getItem("country") || "{}")
    );
    if (isCountryCode.length === 0) {
      if (countries) setSelectedCountry(countries[0]);
    } else {
      setSelectedCountry(JSON.parse(localStorage.getItem("country") || "{}"));
    }
  }, [countries]);

  const closePopOver = () => {
    setShowPopover({
      showPopover: false,
      event: undefined,
    });
  };

  const isPidInArray = (pid: any) => {
    return scandidPids.includes(pid);
  };

  const handlePopoverClick = (e: React.MouseEvent) => {
    e.persist();
    setShowPopover({ showPopover: true, event: e });
  };
  const activeTab = history.location.pathname.slice(1);
  let searchParam = new URLSearchParams(history.location.search).get("q");
  const filterParam = new URLSearchParams(history.location.search).get(
    "filter"
  );
  const sortParam = new URLSearchParams(history.location.search).get("sort");

  const searchHandler = (event: any) => {
    if (!history.location.pathname.includes("search")) {
      if (event.key === "Enter" && event.target.value !== "") {
        googleAnalyticsSearchTerm(
          event.target.value,
          history.location.pathname.slice(1)
        );
        history.push({
          pathname: `/${activeTab}/search/`,
          search: `q=${encodeURIComponent(event.target.value)}`,
        });
      }
    } else {
      if (event.key === "Enter" && event.target.value !== "") {
        if (searchParam && !filterParam && !sortParam) {
          history.replace({
            search: `q=${encodeURIComponent(event.target.value)}`,
          });
        } else if (searchParam && filterParam && !sortParam) {
          history.replace({
            search: `q=${encodeURIComponent(
              event.target.value
            )}&filter=${encodeURIComponent(filterParam)}`,
          });
        } else if (searchParam && filterParam && sortParam) {
          history.replace({
            search: `q=${encodeURIComponent(
              event.target.value
            )}&filter=${encodeURIComponent(
              filterParam
            )}&sort=${encodeURIComponent(sortParam)}`,
          });
        } else if (searchParam && !filterParam && sortParam) {
          history.replace({
            search: `q=${encodeURIComponent(
              event.target.value
            )}&sort=${encodeURIComponent(sortParam)}`,
          });
        }
      } else if (event.key === "Enter" && event.target.value === "") {
        history.goBack();
      }
    }
  };

  const changeCountryCode = (item: any) => {
    console.log(item);
    setIsCardVisible(false);
    setSelectedCountry(item);
    localStorage.setItem("country", JSON.stringify(item));
    localStorage.setItem("pid", item.pid);
    fetchDatafn();
  };

  return (
    <div className={`${styles.header} ion-hide-sm-down`}>
      <IonHeader className="padt10 padb10">
        <IonGrid fixed className="ion-no-padding">
          <IonRow className="ion-justify-content-between ion-align-items-center">
            <IonImg
              className={`${styles.logo}`}
              src={webLogoSrc}
              onClick={() => history.push("/shopping")}
            ></IonImg>
            {data &&
              data?.map((item: any, index: any) => {
                if (item.type === "searchBar") {
                  return (
                    <div
                      className={`${styles.searchBarContainer} ion-hide-md-down`}
                      key={index}
                    >
                      <IonSearchbar
                        className={`ion-no-padding`}
                        placeholder={item.display_name}
                        value={""}
                        onKeyPress={(event: any) => searchHandler(event)}
                      ></IonSearchbar>
                    </div>
                  );
                }
              })}
            {searchBar && (
              <div className={`${styles.searchBarContainer} ion-hide-md-down`}>
                <IonSearchbar
                  className={`ion-no-padding`}
                  placeholder="Search"
                  value={searchParam}
                  onKeyPress={(event: any) => searchHandler(event)}
                ></IonSearchbar>
              </div>
            )}
            <div className="d-flex">
              <div>
                <IonButton
                  className={
                    activeTab === "shopping"
                      ? `${styles.active} ${styles.underline}`
                      : ""
                  }
                  onClick={() => history.replace(`/shopping`)}
                >
                  Shopping
                </IonButton>
                <IonButton
                  className={
                    activeTab === "deals"
                      ? `${styles.active} ${styles.underline}`
                      : ""
                  }
                  onClick={() => history.replace(`/deals`)}
                >
                  Deals
                </IonButton>
                <IonButton
                  className={
                    activeTab === "coupons"
                      ? `${styles.active} ${styles.underline}`
                      : ""
                  }
                  onClick={() => history.replace(`/coupons`)}
                >
                  Coupons
                </IonButton>
                {isPidInArray(partnerPid) && (
                  <IonButton
                    onClick={() => {
                      try {
                        window.open("https://blog.scandid.deals", "_blank");
                      } catch (error) {
                        console.error("Error opening link:", error);
                      }
                    }}
                  >
                    Blog
                  </IonButton>
                )}
                {countries && selectedCountry && (
                  <IonButton
                    onClick={() => setIsCardVisible(!isCardVisible)}
                    className={`${styles.countryButton}`}
                  >
                    <IonNote
                      slot="start"
                      dangerouslySetInnerHTML={{
                        __html: `${selectedCountry?.country?.emoji}`,
                      }}
                      className={styles.countryFlags}
                    />
                    <IonLabel>
                      {` ${selectedCountry?.country?.iso2}`}
                    </IonLabel>
                  </IonButton>
                )}
              </div>
              {showLogin && (
                <IonButtons slot="end">
                  <IonButton onClick={handlePopoverClick}>
                    <IonIcon slot="icon-only" icon={personCircle} />
                  </IonButton>
                </IonButtons>
              )}
            </div>
          </IonRow>
        </IonGrid>
      </IonHeader>
      {isCardVisible && countries && countries.length > 1 && (
        <div
          ref={cardRef}
          className={`${styles.cardContainer} ${
            isCardVisible ? `${styles.showCard}` : ""
          }`}
        >
          <IonCard>
            <IonList className="ion-no-padding">
              {countries?.map((item: any) => {
                return (
                  <div
                    className={`${styles.countryListItem} pad10`}
                    onClick={() => changeCountryCode(item)}
                  >
                    <IonLabel className="primary-title-color marr10 font12 font500">
                      {item?.country?.emoji}
                    </IonLabel>
                    <IonLabel className="primary-title-color font14 font500">
                      {item?.country?.name}
                    </IonLabel>
                  </div>
                );
              })}
            </IonList>
          </IonCard>
        </div>
      )}
      <IonPopover
        isOpen={popoverState.showPopover}
        event={popoverState.event}
        onDidDismiss={() =>
          setShowPopover({ showPopover: false, event: undefined })
        }
        className={`${styles.popOver}`}
      >
        <AccountPopoverContent closePopOver={closePopOver} />
      </IonPopover>
    </div>
  );
};
