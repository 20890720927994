import {
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonPage,
  IonHeader,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonText,
  IonButton,
  IonLoading,
} from "@ionic/react";
import styles from "./Cashback.module.scss";
import BalanceCard from "../../../components/balanceCard/balanceCard";
import {
  chevronBack,
  cash,
  chevronBackOutline,
  chevronForwardOutline,
} from "ionicons/icons";
import { Header } from "../../../components/Header/Header";
import { useEffect, useState } from "react";
import CashbackCard from "../../../components/cashbackCard/CashbackCard";
import { getPoints } from "../../../utils/getApiData";
import { convertSecToDate } from "../../../utils/dateTimeHelper";
import CashbackBalanceDetailModal from "../../../components/Modals/cashback-detail-modal/CashbackBalanceDetailModal";
import {
  cashbackAmountPoints,
  cashbackColumns,
} from "../../../utils/cashbackHelpers";
import { usePartner } from "../../../Contexts/PartnerContext";
export const Cashback = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { partnerPid, clientData } = usePartner();
  const itemsPerPage = 10;
  const [isMobileResolution, setIsMobileResolution] = useState(
    window.innerWidth <= 768
  );
  const [transactions, setTransactions] = useState<any>([]);
  const [columns, setColumns] = useState<any>({});
  const [totalPages, setTotalPages] = useState<any>(0);
  const [pageDetails, setPageDetails] = useState<any>({
    totalCount: 0.0,
    available: cashbackAmountPoints(clientData?.isPoints, 0.0),
    blocked: cashbackAmountPoints(clientData?.isPoints, 0.0),
    totalEarned: cashbackAmountPoints(clientData?.isPoints, 0.0),
    balanceList: [
      {
        points: cashbackAmountPoints(clientData?.isPoints, 0.0),
        displayName: "Available",
      },
      {
        points: cashbackAmountPoints(clientData?.isPoints, 0.0),
        displayName: "Pending",
      },
      {
        points: cashbackAmountPoints(clientData?.isPoints, 0.0),
        displayName: "Cancelled",
      },
      {
        points: cashbackAmountPoints(clientData?.isPoints, 0.0),
        displayName: "Expired",
      },
      {
        points: cashbackAmountPoints(clientData?.isPoints, 0.0),
        displayName: "Redeemed",
      },
      {
        points: cashbackAmountPoints(clientData?.isPoints, 0.0),
        displayName: "Total Earned",
      },
    ],
  });
  const [showLoading, setShowLoading] = useState(false);
  const [
    isCashbackBalanceDetailModalOpen,
    setIsCashbackBalanceDetailModalOpen,
  ] = useState(false);

  const nextPage = () => {
    if (pageDetails?.totalCount >= transactions?.length) {
      getCashbackPoints(currentPage + 1, itemsPerPage);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  const openCashbackBalanceDetailModal = () => {
    setIsCashbackBalanceDetailModalOpen(true);
  };

  const closeCashbackBalanceDetailModal = () => {
    setIsCashbackBalanceDetailModalOpen(false);
  };

  const getCashbackPoints = async (page: any, num: any) => {
    const profileData: any = JSON.parse(
      localStorage.getItem("profile") || "{}"
    );
    const availableUserPoints = localStorage.getItem("availUserPts");
    const RequestParams = {
      salt: profileData?.salt,
      profileid: profileData?.profileid,
      page: page,
      num: num,
      token: profileData?.token,
      ext_mem_id: profileData?.extMemberId,
    };
    if (!isMobileResolution) {
      setShowLoading(true);
    }
    try {
      const { data }: any = await getPoints({ ...RequestParams });
      console.log(data);
      setPageDetails({
        totalCount: data?.totalCount,
        available: cashbackAmountPoints(
          clientData?.isPoints,
          data?.available ?? availableUserPoints ?? 0.0
        ),
        blocked: cashbackAmountPoints(
          clientData?.isPoints,
          data?.blocked
        ),
        totalEarned: cashbackAmountPoints(
          clientData?.isPoints,
          data?.totalEarned
        ),
        balanceList: [
          { points: cashbackAmountPoints(clientData?.isPoints, data?.available || 0.0), displayName: "Available" },
          { points: cashbackAmountPoints(clientData?.isPoints, data?.blocked || 0.0), displayName: "Pending" },
          { points: cashbackAmountPoints(clientData?.isPoints, data?.cancelled || 0.0), displayName: "Cancelled" },
          { points: cashbackAmountPoints(clientData?.isPoints, data?.expired || 0.0), displayName: "Expired" },
          { points: cashbackAmountPoints(clientData?.isPoints, data?.redeemed || 0.0), displayName: "Redeemed" },
          { points: cashbackAmountPoints(clientData?.isPoints, data?.totalEarned || 0.0), displayName: "Total Earned" },
        ],
      });
      setTotalPages(Math.ceil(data?.totalCount / itemsPerPage));

      if (data?.totalCount >= transactions?.length) {
        const added = [...transactions, ...data?.transactions];
        setTransactions(added);
        setCurrentPage(page);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowLoading(false);
    }
  };

  const getColumnsByPartner = (partnerId: string) => {
    return Object.entries(cashbackColumns).filter(
      (element) => element[0] === partnerId
    );
  };

  useEffect(() => {
    getCashbackPoints(0, itemsPerPage);
  }, [clientData]);

  useEffect(() => {
    const currColumns = getColumnsByPartner(partnerPid);
    if (currColumns.length !== 0) {
      setColumns(currColumns?.[0][1]);
    } else {
      setColumns({
        date: true,
        merchant: true,
        saleAmount: true,
        estimate: true,
        status: true,
      });
    }
    const handleResize = () => {
      setIsMobileResolution(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <IonPage className={`${styles.cashBack}`}>
      <Header searchBar={false} />
      <IonHeader className="ion-no-padding ion-no-border ion-hide-sm-up">
        <IonToolbar className="header-primary-bg-color header-text-color">
          <IonGrid fixed>
            <IonRow>
              <IonButtons>
                <IonBackButton
                  icon={chevronBack}
                  defaultHref="/shopping"
                ></IonBackButton>
              </IonButtons>
              <IonTitle className={styles.pageTitle}>{"CASHBACK"}</IonTitle>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent className={`${styles.cashBackList}`}>
        <div className={`${styles.balanceCards}`}>
          <BalanceCard
            text="Available"
            amount={pageDetails?.available}
            openCashbackBalanceDetailModal={() => {}}
            isOpenable={false}
          />
          <BalanceCard
            text="Pending"
            amount={pageDetails?.blocked}
            openCashbackBalanceDetailModal={() => {}}
            isOpenable={false}
          />
          <BalanceCard
            text="Total Earned"
            amount={pageDetails?.totalEarned}
            openCashbackBalanceDetailModal={openCashbackBalanceDetailModal}
            isOpenable={true}
          />
        </div>
        {!isMobileResolution && transactions?.length > 0 && (
          <IonGrid fixed>
            <IonItem lines="none" className="border-r10 ion-bg-white">
              <div
                className={`${styles.contentContainer} ion-no-padding ion-no-margin`}
              >
                <IonGrid className="ion-no-padding ">
                  <IonRow className={`${styles.tableTitle}`}>
                    {columns?.date && (
                      <IonCol className={`${styles.dynamicCol}`}>
                        <h6 className="font10 font600">DATE</h6>
                      </IonCol>
                    )}
                    {columns?.merchant && (
                      <IonCol className={`${styles.dynamicCol}`}>
                        <h6 className="font10 font600">MERCHANT</h6>
                      </IonCol>
                    )}
                    {columns?.saleAmount && (
                      <IonCol className={`${styles.dynamicCol}`}>
                        <h6 className="font10 font600">SALE AMOUNT</h6>
                      </IonCol>
                    )}
                    {columns?.estimate && (
                      <IonCol className={`${styles.dynamicCol}`}>
                        <h6 className="font10 font600">ESTIMATE</h6>
                      </IonCol>
                    )}
                    {columns?.status && (
                      <IonCol className={`${styles.dynamicCol}`}>
                        <h6 className="font10 font600">STATUS</h6>
                      </IonCol>
                    )}
                  </IonRow>
                  {transactions &&
                    transactions
                      ?.slice(
                        currentPage * itemsPerPage,
                        currentPage * itemsPerPage + itemsPerPage
                      )
                      ?.map((item: any, index: number) => {
                        return (
                          <IonRow
                            key={index}
                            className="ion-align-items-center"
                          >
                            {columns.date && (
                              <IonCol className={`${styles.dynamicCol}`}>
                                <h6 className="font10 font400 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ">
                                  {convertSecToDate(item?.action_on)}
                                </h6>
                              </IonCol>
                            )}
                            {columns.merchant && (
                              <IonCol className={`${styles.dynamicCol}`}>
                                <h6 className="font10 font400 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ">
                                  {item?.store}
                                </h6>
                              </IonCol>
                            )}
                            {columns.saleAmount && (
                              <IonCol className={`${styles.dynamicCol}`}>
                                <h6
                                  className={`font10 font400 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ${styles.balanceAmount}`}
                                >
                                  <IonIcon icon={cash} color="success" />
                                  <IonText className={`${styles.amount}`}>
                                    {cashbackAmountPoints(
                                      clientData?.isPoints,
                                      item?.productPrice
                                    )}
                                  </IonText>
                                </h6>
                              </IonCol>
                            )}
                            {columns.estimate && (
                              <IonCol className={`${styles.dynamicCol}`}>
                                <h6
                                  className={`font10 font400 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ${styles.balanceAmount}`}
                                >
                                  <IonIcon icon={cash} color="success" />
                                  <IonText className={`${styles.amount}`}>
                                    {cashbackAmountPoints(
                                      clientData?.isPoints,
                                      item?.points
                                    )}
                                  </IonText>
                                </h6>
                              </IonCol>
                            )}
                            {columns.status && (
                              <IonCol className={`${styles.dynamicCol}`}>
                                <h6 className="font10 font400 ion-no-margin ion-no-padding padl10 padr10 padt10 padb10 ">
                                  {item?.status}
                                </h6>
                              </IonCol>
                            )}
                          </IonRow>
                        );
                      })}
                </IonGrid>
                <div className={`${styles.pagination}`}>
                  <IonButton onClick={prevPage} disabled={currentPage === 0}>
                    <IonIcon icon={chevronBackOutline} />
                    {/* Previous */}
                  </IonButton>
                  <span>
                    {/* Page {currentPage} of {totalPages}  */}
                    {currentPage + 1}
                  </span>
                  <IonButton
                    onClick={nextPage}
                    disabled={currentPage === totalPages - 1}
                  >
                    {/* Next */}
                    <IonIcon icon={chevronForwardOutline} />
                  </IonButton>
                </div>
              </div>
            </IonItem>
          </IonGrid>
        )}

        {isMobileResolution && transactions?.length > 0 && (
          <IonGrid fixed>
            {transactions &&
              transactions?.map((item: any, index: number) => {
                return (
                  <CashbackCard
                    key={index}
                    merchant={item?.store}
                    date={convertSecToDate(item?.action_on)}
                    amount={item?.productPrice}
                    logo={item?.storeLogo}
                  />
                );
              })}

            {!(currentPage === totalPages - 1) && (
              <IonButton
                className={`${styles.loadMoreBtn}`}
                onClick={() => {
                  nextPage();
                }}
              >
                Load More..
              </IonButton>
            )}
          </IonGrid>
        )}

        {!transactions ||
          (transactions?.length === 0 && (
            <div className={`${styles.noTransaction}`}>
              <p className="paragraph-primary-text-color font600 font18">
                No Transactions Found !
              </p>
            </div>
          ))}
      </IonContent>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Please wait..."}
      />
      <CashbackBalanceDetailModal
        isOpen={isCashbackBalanceDetailModalOpen}
        onClose={closeCashbackBalanceDetailModal}
        data={pageDetails?.balanceList}
      />
    </IonPage>
  );
};
