import { createContext, useContext, useEffect, useState } from "react";
import { devPartnerList } from "../PartnerConfig/devPartnerList";
import { prodPartnerList } from "../PartnerConfig/prodPartnerList";

const PartnerContext = createContext<any>(null);

interface Partner {
  domain: string;
  pid: string;
  key: string;
  theme: string;
  mobLogoSrc: string;
  webLogoSrc: string;
  showLogin: boolean;
}

interface PartnerList {
  [key: string]: Partner;
}

const PartnerContextProvider = ({ children }: any) => {
  let PartnerList: PartnerList;
  if (process.env.REACT_APP_PARTNER_CONFIG === "PRODUCTION") {
    PartnerList = prodPartnerList;
  } else {
    PartnerList = devPartnerList;
  }
  const [theme, setTheme] = useState<any>();
  const [partnerPid, setPartnerPid] = useState<any>();
  const [imgLogoSrc, setImgLogoSrc] = useState<any>();
  const [webLogoSrc, setWebLogoSrc] = useState<any>();
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [clientData, setClientData] = useState<any>();
  useEffect(() => {
    let params = getQueryParams();
    if (typeof params?.pid !== "undefined" && params?.pid !== "") {
      localStorage.setItem("pid", params?.pid);
      const partner = getPartnerById(params?.pid);
      localStorage.setItem("key", partner[0][1]?.key);
      setTheme(partner[0][1]?.theme);
      setPartnerPid(partner[0][1]?.pid)
      setImgLogoSrc(partner[0][1]?.mobLogoSrc);
      setWebLogoSrc(partner[0][1]?.webLogoSrc);
      setShowLogin(partner[0][1]?.showLogin)
      setClientData(partner[0][1]);
    } else {
      const parterDomain = window.location.hostname;
      const partner = getPartnerByDomain(parterDomain);
      if (partner.length !== 0) {
        if (!localStorage.getItem("key") && !localStorage.getItem("pid")) {
          localStorage.setItem("pid", partner[0][1].pid);
          localStorage.setItem("key", partner[0][1].key);
        }
        setTheme(partner[0][1]?.theme);
        setPartnerPid(partner[0][1]?.pid);
        setImgLogoSrc(partner[0][1]?.mobLogoSrc);
        setWebLogoSrc(partner[0][1]?.webLogoSrc);
        setShowLogin(partner[0][1]?.showLogin)
        setClientData(partner[0][1]);
      } else {
        window.location.href = 'https://paynearby.scandid.in'
      }
    }
  }, []);

  const getQueryParams = () =>
    window.location.search
      .replace("?", "")
      .split("&")
      .reduce(
        (r: any, e: any) => (
          (r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r
        ),
        {}
      );

  const getPartnerById = (pid: string | null) => {
    return Object.entries(PartnerList).filter(
      (element) => element[1]?.pid === pid
    );
  };

  const getPartnerByDomain = (partnerDomain: string) => {
    return Object.entries(PartnerList).filter(
      (element) => element[1]?.domain === partnerDomain
    );
  };

  return (
    <PartnerContext.Provider
      value={{
        theme: theme,
        partnerPid: partnerPid,
        imgLogoSrc: imgLogoSrc,
        webLogoSrc: webLogoSrc,
        showLogin: showLogin,
        clientData: clientData,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

const usePartner = () => useContext(PartnerContext);
export { usePartner, PartnerContextProvider };
