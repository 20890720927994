import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonBackButton,
  IonRow,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { Account } from "../../../components/Account/Account";

export const FeedbackUs: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-padding-horizontal ion-hide-sm-down">
        <IonGrid fixed>
          <IonToolbar>
            <IonTitle className="ion-no-padding font18 font600">
              Feedback & Support
            </IonTitle>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonHeader className="ion-hide-sm-up">
        <IonGrid fixed>
          <IonToolbar>
            <IonRow>
              <IonBackButton
                className="ion-no-padding"
                icon={chevronBack}
              ></IonBackButton>
              <IonTitle className="ion-padding-horizontal font18 font600">
                Feedback & Support
              </IonTitle>
            </IonRow>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonContent>
        <IonGrid fixed className="h90 ion-padding-horizontal">
          <h2>We Value Your Feedback</h2>
          <p>
            At Scandid, we are committed to providing the best online shopping
            experience. Your feedback is essential in helping us improve our
            services, enhance the user experience, and deliver better deals.
            Whether you have a comment about a recent order, a service
            experience, or a suggestion for improvement, we want to hear from
            you.
          </p>
          <h3>Share Your Feedback</h3>
          <p>
            Please fill out the form below to provide your feedback on:
            <ul>
              <li>
                <strong>Order Experience:</strong> Were you satisfied with the
                deals and checkout process?
              </li>
              <li>
                <strong>Service & Support:</strong> Did you receive the
                assistance you needed?
              </li>
              <li>
                <strong>Suggestions & Complaints:</strong> Have ideas or
                concerns? Let us know.
              </li>
            </ul>
          </p>
          <h3>Feedback Form</h3>
          <IonList>
            <IonItem>
              <IonLabel>Name: [Enter Your Name]</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Email: [Enter Your Email Address]</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Order ID (if applicable): [Enter Order ID]</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                Rate Your Experience: [Select from 1 to 5 Stars]
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Your Feedback: [Enter Your Message]</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Upload Screenshot (Optional): [Upload File]</IonLabel>
            </IonItem>
          </IonList>
          <p>[Submit Feedback] - button</p>
          <p>
            All responses are reviewed by our team, and we take necessary
            actions to improve our services.
          </p>
          <h3>Need Assistance?</h3>
          <p>
            If you require immediate support regarding an order, a deal, or a
            service-related issue, please reach out to us:
          </p>
          <p>
            <strong>Email:</strong>
            <a href="mailto:info@scandid.us" className="padl10 padr10">
              support@scandid.deals
            </a>
          </p>
          <h3>Why Your Feedback Matters</h3>
          <p>
            <ul>
              <li>
                <strong>Continuous Improvement:</strong> Your insights help us
                refine our platform and services.
              </li>
              <li>
                <strong>Enhanced User Experience:</strong> We use feedback to
                improve website navigation, deal discovery, and overall
                usability.
              </li>
              <li>
                <strong>Better Deals & Offers:</strong> Your suggestions enable
                us to bring more relevant and exclusive discounts.
              </li>
            </ul>
          </p>
          <p>
            Thank you for sharing your feedback and being a part of the Scandid
            community. Visit
            <a
              href="https://us.scandid.deals/"
              target="_blank"
              rel="noreferrer"
              className="padl10 padr10"
            >
              us.scandid.deals
            </a>
            to explore the latest deals and offers.
          </p>
        </IonGrid>
        <Account isHomePage={false} />
      </IonContent>
    </IonPage>
  );
};
