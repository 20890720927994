import { IonButton, IonGrid, IonLabel, IonRow } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./Account.module.scss";
import { usePartner } from "../../Contexts/PartnerContext";

interface AccountProps {
  isHomePage: boolean;
}

export const Account: React.FC<AccountProps> = (props: any) => {
  const history = useHistory();
  const navigateTo = (path: string) => {
    history.push(path);
  };
  let countryCode = window.location.hostname.split(".")[0];
  const { clientData } = usePartner();

  return (
    <>
      {clientData?.isFooter &&
        (countryCode === "us" || countryCode === "uk") && (
          <div className={`${styles.linkButtonContainer}`}>
            <IonGrid fixed>
              <IonRow style={{ width: "100%", justifyContent: "center" }}>
                <div className={`${styles.linkButtonWrapper}`}>
                  {!props?.isHomePage && (
                    <IonButton
                      className={`clear-btn marr10 ${styles.linkButton}`}
                      onClick={() => navigateTo(`/shopping`)}
                    >
                      <IonLabel>Home</IonLabel>
                    </IonButton>
                  )}
                  <IonButton
                    className={`clear-btn marr10 ${styles.linkButton}`}
                    onClick={() => navigateTo(`/about-us/${countryCode}`)}
                  >
                    <IonLabel> About Us</IonLabel>
                  </IonButton>
                  <IonButton
                    className={`clear-btn marr10 ${styles.linkButton}`}
                    onClick={() => navigateTo(`/contact-us/${countryCode}`)}
                  >
                    <IonLabel>Contact Us</IonLabel>
                  </IonButton>
                  <IonButton
                    className={`clear-btn marr10 ${styles.linkButton}`}
                    onClick={() => navigateTo(`/faq/${countryCode}`)}
                  >
                    <IonLabel>Faq</IonLabel>
                  </IonButton>
                  {/* <IonButton
            className={`clear-btn marr10 ${styles.linkButton}`}
            onClick={() => navigateTo(`/feedback/${countryCode}`)}
          >
            <IonLabel>Feedback</IonLabel>
          </IonButton> */}
                  <IonButton
                    className={`clear-btn marr10 ${styles.linkButton}`}
                    onClick={() => navigateTo(`/privacy-policy/${countryCode}`)}
                  >
                    <IonLabel>Privacy Policy</IonLabel>
                  </IonButton>
                  <IonButton
                    className={`clear-btn marr10 ${styles.linkButton}`}
                    onClick={() =>
                      navigateTo(`/terms-and-conditions/${countryCode}`)
                    }
                  >
                    <IonLabel>Terms and conditions</IonLabel>
                  </IonButton>
                </div>
              </IonRow>
            </IonGrid>
          </div>
        )}
    </>
  );
};
