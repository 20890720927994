import {
  IonBackButton,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React from "react";
import { Account } from "../../../components/Account/Account";

export const PrivacyPolicyUS: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-padding-horizontal ion-hide-sm-down">
        <IonGrid fixed>
          <IonToolbar>
            <IonTitle className="ion-no-padding font18 font600">
              Privacy Policy
            </IonTitle>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonHeader className="ion-hide-sm-up">
        <IonGrid fixed>
          <IonToolbar>
            <IonRow>
              <IonBackButton
                className="ion-no-padding"
                icon={chevronBack}
              ></IonBackButton>
              <IonTitle className="ion-padding-horizontal font18 font600">
                Privacy Policy
              </IonTitle>
            </IonRow>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonContent>
        <IonGrid fixed className="ion-padding-horizontal">
          <h1 className="font18 font700">Privacy Policy for Scandid US</h1>
          <p className="font14 font400">
            Scandid US ("we," "us," or "our") is committed to protecting your
            privacy. This Privacy Policy outlines how we collect, use, disclose,
            and safeguard your information when you visit our website or use our
            services (collectively referred to as the "Services"). By accessing
            or using our Services, you agree to the terms set out in this
            Privacy Policy.
          </p>

          <h1 className="font18 font600">Information We Collect</h1>
          <h2 className="font16 font600">Personal Information:</h2>
          <p className="font14 font400">
            We may collect personal information that you provide directly to us,
            such as your name, email address, phone number, and mailing address
            when you register for an account, subscribe to our newsletters,
            participate in surveys, or access deals, coupons, and discounts.
          </p>

          <h2 className="font16 font600">Technical Information:</h2>
          <p className="font14 font400">
            We automatically gather certain technical information when you use
            our Services, including your IP address, browser type, operating
            system, and browsing behavior. This helps us improve our Services
            and user experience.
          </p>

          <h2 className="font16 font600">Cookies and Tracking Technologies:</h2>
          <p className="font14 font400">
            We use cookies and similar technologies to collect data about your
            interactions with our Services. You can manage cookie preferences
            through your browser settings.
          </p>

          <h2 className="font16 font600">Third-Party Information:</h2>
          <p className="font14 font400">
            If you log in via third-party services like Facebook or Google, we
            may collect additional information about you from those platforms.
          </p>

          <h2 className="font16 font600">Deals, Coupons, and Discount Data:</h2>
          <p className="font14 font400">
            When you access or use deals, coupons, and discounts available
            through our platform, we may collect transaction details such as the
            type of offer, the amount of discount applied, and the stores or
            services from which you make purchases. This information is used to
            facilitate the application of the deal, coupon, or discount to your
            account.
          </p>

          <h2 className="font16 font600">How We Use Your Information</h2>
          <p className="font14 font400">
            We may use the information we collect for various purposes,
            including:
          </p>
          <ul>
            <li className="font14 font400">
              To provide and maintain our Services, including tracking deals and
              the application of discounts.
            </li>
            <li className="font14 font400">
              To personalize your experience and improve user satisfaction, such
              as offering relevant promotions and discounts.
            </li>
            <li className="font14 font400">
              To communicate with you about updates, promotions, new offers, and
              relevant content.
            </li>
            <li className="font14 font400">
              To analyze usage patterns to enhance our Services.
            </li>
            <li className="font14 font400">
              To comply with legal obligations and protect our rights.
            </li>
          </ul>

          <h2 className="font16 font600">Sharing Your Information</h2>
          <p className="font14 font400">
            We do not sell or rent your personal information to third parties.
            However, we may share your information in the following
            circumstances:
          </p>

          <h2 className="font16 font600">Service Providers:</h2>
          <p className="font14 font400">
            We may share your information with third-party service providers
            that assist us in operating our Services, such as payment
            processing, offer tracking, and data analysis.
          </p>

          <h2 className="font16 font600">Legal Compliance:</h2>
          <p className="font14 font400">
            We may disclose your information if required by law or in response
            to valid requests from public authorities.
          </p>

          <h2 className="font16 font600">Business Transfers:</h2>
          <p className="font14 font400">
            In the event of a merger, acquisition, or sale of all or part of our
            assets, your information may be transferred as part of that
            transaction.
          </p>

          <h2 className="font16 font600">
            Deals and Discount-Specific Information Sharing:
          </h2>
          <p className="font14 font400">
            We may share your transaction details (e.g., purchase information,
            applied discounts) with third-party partners involved in processing
            or redeeming deals and offers. These partners may include retailers,
            payment processors, and other entities facilitating the application
            of offers.
          </p>

          <p className="font14 font400">
            We may also share data related to fraud prevention in the context of
            deals and discounts with our affiliates or third-party partners to
            help protect your account and the program.
          </p>

          <h2 className="font16 font600">Data Security</h2>
          <p className="font14 font400">
            We take reasonable security measures to protect your personal
            information from unauthorized access and misuse. However, no method
            of transmission over the internet or electronic storage is 100%
            secure. Therefore, we cannot guarantee absolute security.
          </p>

          <h2 className="font16 font600">Your Rights</h2>
          <p className="font14 font400">You have the right to:</p>
          <ul>
            <li className="font14 font400">
              Access the personal information we hold about you, including
              information related to deals and discounts.
            </li>
            <li className="font14 font400">
              Request corrections to any inaccurate or incomplete data.
            </li>
            <li className="font14 font400">
              Request deletion of your personal information in certain
              circumstances, including data related to offers and discounts.
            </li>
          </ul>

          <h2 className="font16 font600">Children's Privacy</h2>
          <p className="font14 font400">
            Our Services are not intended for children under the age of 13. We
            do not knowingly collect personal information from children under
            13. If we become aware that we have unintentionally collected
            personal information from a child under 13 without parental consent,
            we will take steps to delete such information.
          </p>

          <h2 className="font16 font600">Third-Party Links</h2>
          <p className="font14 font400">
            Our Services may contain links to third-party websites. We are not
            responsible for the privacy practices of those websites. We
            encourage you to review their privacy policies before providing any
            personal information.
          </p>

          <h2 className="font16 font600">Marketing Choices</h2>
          <p className="font14 font400">
            You may opt-in to receive promotional communications from us via
            email or text messages. You can unsubscribe from these
            communications at any time by following the instructions provided in
            those messages.
          </p>

          <h2 className="font16 font600">Deals and Discount Communications:</h2>
          <p className="font14 font400">
            In addition to general communications, we may send you emails or
            notifications related to special deals, new offers, and promotional
            discounts that may increase your savings.
          </p>

          <h2 className="font16 font600">Changes to This Privacy Policy</h2>
          <p className="font14 font400">
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with the updated effective date. Your
            continued use of the Services after any modifications indicates your
            acceptance of the revised policy.
          </p>
        </IonGrid>
        <Account isHomePage={false} />
      </IonContent>
    </IonPage>
  );
};
