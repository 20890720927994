import {
  IonGrid,
  IonRow,
  IonCol,
  IonTitle,
  IonContent,
  IonPage,
  IonCard,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonLabel,
  IonFooter,
  IonButton,
  IonBackButton,
  IonLoading,
  IonIcon,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { chevronBack, shareSocialOutline } from "ionicons/icons";
import { Cashback, Footer, Header, NoData } from "../../../components/index.js";
import { getDealDetail } from "../../../utils/getApiData";
import { useUser } from "../../../Contexts/UserContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Keyboard, Scrollbar, Zoom } from "swiper";
import {
  esdDealDetailThumbnail,
  bannerThumbnail,
} from "../../../thumbnailConfig";
import { ShareProduct } from "../../../components/index";
import styles from "./DealDetailPage.module.scss";
import ReactGA from "react-ga4";

import "swiper/swiper.min.css";
import "@ionic/react/css/ionic-swiper.css";
import "swiper/css/pagination";
import { googleAnalyticsViewStore } from "../../../utils/reactGa";
import { usePartner } from "../../../Contexts/PartnerContext";
import { RedirectAskModal } from "../../../components/Modals/RedirectAskModal/RedirectAskModal";
import { Account } from "../../../components/Account/Account";

type Param = {
  id: any;
};

export const DealDetailPage = () => {
  const [dealData, setDealData] = useState<any>();
  const [showLoading, setShowLoading] = useState(true);
  const [shareModal, setShareModal] = useState(false);
  const { partnerPid } = usePartner();
  const { id } = useParams<Param>();
  const user = useUser();
  const history = useHistory();
  const { clientData } = usePartner();

  const [redirectModal, setRedirectModal] = useState({
    show: false,
  });

  const presentModal = () => {
    if (clientData?.showProceedWindow) {
      setRedirectModal({
        show: true,
      });
    } else {
      gaHandler();
      redirectHandler(dealData.store, dealData.url, dealData?.rewards_campaign);
    }
  };

  const handleModalDismiss = (isRedirected: boolean) => {
    if (isRedirected) {
      gaHandler();
      redirectHandler(dealData.store, dealData.url, dealData?.rewards_campaign);
    }
    setRedirectModal({ show: false });
  };

  const RequestParams = {
    page: 1,
    sendSelectedTab: 0,
    id: id,
    ...(user?.profileid && { profileid: user?.profileid }),
  };

  const gaHandler = () => {
    googleAnalyticsViewStore(dealData.logParams, dealData.url);
  };

  const redirectHandler = (store: any, url: any, earnUpto: any) => {
    if (store === "amazon" || store === "flipkart") {
      history.push({
        pathname: `/commission/${store}`,
        search: `url=${encodeURIComponent(url)}&noCashback=${
          earnUpto.noCashback
        }&displayText=${encodeURIComponent(
          earnUpto.displayText
        )}&title=${encodeURIComponent(dealData.title)}`,
      });
    } else {
      window.open(url, "_blank");
    }
  };

  const shareButtonHandler = () => {
    setShareModal(true);
  };

  const handleImageFailure = (e: any) => {
    e.target.src =
      partnerPid === "esd" ? esdDealDetailThumbnail : bannerThumbnail;
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: history.location.pathname + history.location.search,
    });
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getDealDetail(RequestParams);
        setDealData(data);
      } catch (err) {
        setDealData("NoData");
      }
      setShowLoading(false);
    })();
  }, []);

  return (
    <IonPage className={styles.dealDetail}>
      <Header searchBar={true} />
      <IonHeader className="ion-no-padding ion-no-border ion-hide-sm-up">
        <IonToolbar className="header-primary-bg-color header-text-color">
          <IonGrid fixed className="ion-no-padding">
            <IonRow>
              <IonButtons className={`${styles.btnContainer}`}>
                <IonBackButton
                  className="ion-no-padding"
                  icon={chevronBack}
                  defaultHref="/deals"
                ></IonBackButton>
              </IonButtons>
              <IonTitle
                className={`${styles.dealDetailTitle} ion-no-padding ion-text-center`}
              >
                DEAL
              </IonTitle>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent className={`${styles.content} ion-hide-md-up`}>
        <div className="scrollableDesign ion-hide-sm-up">
          <div className="scrollable"></div>
        </div>
        {dealData !== undefined &&
          (dealData === null || dealData === "NoData" ? (
            <div className={`${styles.noData}`}>
              <NoData props={"No Data Found !"} />
            </div>
          ) : (
            <IonGrid fixed className={styles.contentGrid}>
              <IonRow>
                <IonCol>
                  <Swiper
                    className={`${styles.swiper}`}
                    modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]}
                    autoplay={{ delay: 2500 }}
                    pagination={{ clickable: true }}
                  >
                    <SwiperSlide>
                      <IonCard>
                        <img
                          src={dealData.img}
                          alt="deal"
                          onError={(e) => handleImageFailure(e)}
                        />
                        <IonIcon
                          icon={shareSocialOutline}
                          className="shareButton icon-dark-color"
                          onClick={() => shareButtonHandler()}
                        ></IonIcon>
                      </IonCard>
                    </SwiperSlide>
                  </Swiper>
                  {
                    <ShareProduct
                      state={{ shareModal, setShareModal }}
                      data={dealData}
                      page={"deal"}
                    />
                  }
                  <div className="ion-padding">
                    <IonCardHeader className="ion-no-padding">
                      {/* <IonCardTitle color="dark">Nike Sneakers</IonCardTitle> */}
                      <IonCardSubtitle className="ion-text-capitalize card-title-color">
                        {dealData.title}
                      </IonCardSubtitle>
                      <span
                        className={`${styles.storeName} font12 ion-text-capitalize card-grey-text-color`}
                      >
                        By {dealData.store}
                      </span>
                    </IonCardHeader>
                    <IonCardContent className="ion-no-padding">
                      <IonGrid className="ion-no-padding">
                        <IonRow className="ion-align-items-center ion-padding-top">
                          <span
                            className={`${styles.offerPrice} ion-padding-end font900 card-title-color`}
                          >
                            <IonLabel>{dealData.finalPriceStr}</IonLabel>
                          </span>
                          <span>
                            <span
                              className={`${styles.originalPrice} card-title-color`}
                            >
                              {dealData.origPriceStr}
                            </span>
                            <IonLabel className="padl10 success">
                              ({dealData.discount.toFixed()}% off)
                            </IonLabel>
                          </span>
                        </IonRow>
                        <IonRow>
                          <Cashback
                            rewardText={dealData?.rewards_campaign}
                            styleName={"cashback"}
                          />
                        </IonRow>
                      </IonGrid>
                      <IonTitle className="ion-no-padding ion-margin-top card-title-color">
                        Description
                      </IonTitle>
                      <span
                        className="card-title-color"
                        dangerouslySetInnerHTML={{ __html: dealData.details }}
                      ></span>
                    </IonCardContent>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          ))}
        {dealData && <Footer />}
      </IonContent>
      <IonContent className={`${styles.content} ion-hide-md-down`}>
        <div className="scrollableDesign ion-hide-sm-up">
          <div className="scrollable"></div>
        </div>
        {dealData !== undefined &&
          (dealData === null || dealData === "NoData" ? (
            <NoData props={"No Data Found !"} />
          ) : (
            <IonGrid fixed className={styles.contentGrid}>
              <IonRow>
                <IonCol size="5">
                  <Swiper
                    className={`${styles.swiper}`}
                    modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]}
                    autoplay={{ delay: 2500 }}
                    pagination={{ clickable: true }}
                  >
                    <SwiperSlide>
                      <IonCard>
                        <img
                          src={dealData.img}
                          alt="deal"
                          onError={(e) => handleImageFailure(e)}
                        />
                        <IonIcon
                          icon={shareSocialOutline}
                          className="shareButton icon-dark-color"
                          onClick={() => shareButtonHandler()}
                        ></IonIcon>
                      </IonCard>
                    </SwiperSlide>
                  </Swiper>
                  {
                    <ShareProduct
                      state={{ shareModal, setShareModal }}
                      data={dealData}
                    />
                  }
                </IonCol>
                <IonCol>
                  <div className="ion-padding">
                    <IonCardHeader className="ion-no-padding">
                      {/* <IonCardTitle color="dark">Nike Sneakers</IonCardTitle> */}
                      <IonCardSubtitle className="ion-text-capitalize card-title-color">
                        {dealData.title}
                      </IonCardSubtitle>
                      <span
                        className={`${styles.storeName} font12 ion-text-capitalize card-grey-text-color`}
                      >
                        By {dealData.store}
                      </span>
                    </IonCardHeader>
                    <IonCardContent className="ion-no-padding">
                      <IonGrid className="ion-no-padding">
                        <IonRow className="ion-align-items-center ion-padding-top">
                          <span
                            className={`${styles.offerPrice} ion-padding-end font900 card-title-color`}
                          >
                            <IonLabel>{dealData.finalPriceStr}</IonLabel>
                          </span>
                          {dealData.orig_price > dealData.final_price ? (
                            <span>
                              <span className={`${styles.originalPrice}`}>
                                {dealData?.origPriceStr}
                              </span>
                              <IonLabel className="padl10" color="success">
                                ({dealData.discount.toFixed()}% off)
                              </IonLabel>
                            </span>
                          ) : (
                            <></>
                          )}
                        </IonRow>
                        <IonRow>
                          <Cashback
                            rewardText={
                              dealData?.rewards_campaign ||
                              dealData?.rewards_campaign?.displayText
                            }
                            styleName={"cashback"}
                          />
                        </IonRow>
                      </IonGrid>
                      <IonTitle className="ion-no-padding ion-margin-top card-title-color">
                        Description
                      </IonTitle>
                      <span
                        dangerouslySetInnerHTML={{ __html: dealData.details }}
                      ></span>
                    </IonCardContent>
                    <IonButton
                      expand="block"
                      fill="solid"
                      className={`${styles.visitBtn} font12 mart10 secondary-btn`}
                      onClick={() => {
                        presentModal();
                      }}
                    >
                      Visit store
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          ))}
        {dealData && <Footer />}
        {dealData && <Account isHomePage={false} />}
      </IonContent>
      {dealData !== undefined && dealData !== null && dealData !== "NoData" && (
        <IonFooter className="ion-hide-md-up">
          <IonGrid fixed>
            <IonRow>
              <IonCol>
                <IonToolbar>
                  <IonGrid className="ion-padding-start" fixed>
                    <IonRow className="ion-align-items-center">
                      <IonCol>
                        <div className="d-flex flex-column">
                          <span
                            className={`${styles.offerPrice} card-title-color`}
                          >
                            <IonLabel className="font900">
                              {dealData.finalPriceStr}
                            </IonLabel>
                          </span>
                          <IonLabel className=" font14 success">
                            ({Math.round(dealData.discount)}% off)
                          </IonLabel>
                        </div>
                      </IonCol>
                      <IonCol>
                        <IonButton
                          shape="round"
                          expand="block"
                          fill="solid"
                          size="default"
                          className="font12 secondary-btn"
                          onClick={() => {
                            presentModal();
                          }}
                        >
                          Visit store
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonToolbar>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      )}
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Please wait..."}
      />

      <RedirectAskModal
        isOpen={redirectModal.show}
        onClose={handleModalDismiss}
      />
    </IonPage>
  );
};
