import { IonButton, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import styles from "./SessionExpired.module.scss";
import { usePartner } from "../../../Contexts/PartnerContext";

export const SessionExpired = () => {
  const { clientData } = usePartner();
  useEffect(() => {
    if (clientData?.redirectUrl) {
      window.location.href = clientData?.redirectUrl;
    }
  }, []);
  return (
    <IonPage className={styles.sessionExpired}>
      <div className={styles.centerbox}>
        <div className={styles.sessionExpired__title}>Session Expired</div>
        <IonButton expand="block" className={`primary-btn back-btn`}>
          Back
        </IonButton>
      </div>
    </IonPage>
  );
};
