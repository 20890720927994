import React from "react";
import { Route, Redirect } from "react-router-dom";
import { usePartner } from "../Contexts/PartnerContext";
import { closeSitePids } from "../utils/pidHelper";
import { alwaysPublicRoutes } from "../utils/routesHelper";

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
}

export const PublicRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { partnerPid } = usePartner();
  const isOpenSite = () => {
    if (alwaysPublicRoutes.includes(rest.path)) {
      return true;
    } else if (closeSitePids.includes(partnerPid)) {
      const profileData: any = JSON.parse(
        localStorage.getItem("profile") || "{}"
      );
      if (profileData?.token) {
        return true;
      }
      return false;
    }
    return true;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isOpenSite() ? (
          <Component {...props} />
        ) : closeSitePids.includes(partnerPid) ? (
          <Redirect to="/session-expired" exact={true} />
        ) : (
          <Redirect to="/shopping" exact={true} />
        )
      }
    />
  );
};

export const ProtectedRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = () => {
    const profileData: any = JSON.parse(
      localStorage.getItem("profile") || "{}"
    );
    if (profileData?.token) {
      return true;
    }
    return false;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/shopping" exact={true} />
        )
      }
    />
  );
};
