import {
  IonCol,
  IonContent,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSearchbar,
  IonLoading,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonTitle,
  IonButton,
  IonCheckbox,
  IonFooter,
  IonIcon,
  IonList,
  IonModal,
  useIonActionSheet,
} from "@ionic/react";
import { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router";
import {
  chevronBack,
  filterOutline,
  swapVerticalOutline,
  close,
} from "ionicons/icons";
import { getSearchedData } from "../../utils/getApiData";
import { Header, NoData } from "../index.js";
import { DealListCard } from "../../pages/deals/deal-components/index.js";
import { ProductGridItem } from "../../pages/shopping/shopping-components/product-grid-list/ProductGridItem";
import styles from "./Search.module.scss";
import { useUser } from "../../Contexts/UserContext";
import ReactGA from 'react-ga4'
import { googleAnalyticsSearchTerm } from "../../utils/reactGa";
import { SortFilter } from "../index.js";
import { CouponListCard } from "../../pages/coupons/coupon-components";
import { Account } from "../Account/Account";

type Params = {
  searchPage: any;
};

export const Search = () => {
  const history = useHistory();
  let { searchPage } = useParams<Params>();
  let searchParam = new URLSearchParams(history.location.search).get('q');
  const [searchTerm, setSearchTerm] = useState<any>(searchParam);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [showLoading, setShowLoading] = useState(true);
  const [displayCategory, setDisplayCategory] = useState<any>("");
  const [filters, setFilters] = useState<any>([]);
  const [subCategory, setSubCategory] = useState<any>([]);
  const [appliedFilter, setAppliedFilter] = useState<any>({});
  const [selectedFilter, setSelectedFilter] = useState<any>();
  const [selectedSort, setSelectedSort] = useState<any>();
  const user = useUser();
  const modal = useRef<HTMLIonModalElement>(null);

  const filterParam = new URLSearchParams(history.location.search).get('filter');
  const sortParam = new URLSearchParams(history.location.search).get('sort');
  const [isMobileResolution, setIsMobileResolution] = useState(
    window.innerWidth <= 768
  );


  if (searchPage.includes('shopping')) {
    searchPage = 'shopping'
  } else if (searchPage.includes('deals' || 'deal')) {
    searchPage = 'deals'
  } else if (searchPage.includes('coupon' || 'coupons')) {
    searchPage = 'coupons'
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobileResolution(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setFilteredData([]);
    setSearchTerm(searchParam);
    setPage(1);
    setDisableInfiniteScroll(false);
    googleAnalyticsSearchTerm(searchParam, history.location.pathname.split('/')?.[1]);
  }, [searchParam])


  useEffect(() => {
    if (filterParam) {
      setSelectedFilter(JSON.parse(filterParam))
      setAppliedFilter(JSON.parse(filterParam))
      setFilteredData([]);
      setPage(1);
    } else {
      setSelectedFilter(undefined);
      setAppliedFilter({});
      setFilteredData([]);
      setPage(1);
    }
  }, [filterParam]);

  useEffect(() => {
    if (sortParam) {
      setSelectedSort(sortParam)
      setFilteredData([]);
      setPage(1);
    } else {
      setSelectedSort(undefined);
      setFilteredData([]);
      setPage(1);
    }
  }, [sortParam])

  const requestParams = {
    pageType: "search",
    sendSelectedTab: 1,
    num: 10,
    page: page,
    prefix: searchTerm,
    type: searchPage === "shopping" ? "products" : searchPage,
    ...(user?.profileid && { profileid: user?.profileid }),
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: history.location.pathname + history.location.search });
  }, [history]);

  useEffect(() => {
    const unlisten = history.listen(() => {
        closeModal();
        closeActionSheet();
    });
    return () => {
        unlisten();
    };
}, [history])

  useEffect(() => {
    if (selectedFilter || selectedSort) {
      if (page === 1) {
        setFilteredData([]);
      }
      (async () => {
        if (page === 1) {
          setShowLoading(true);
        }
        try {
          if (searchTerm !== "") {
            const {
              data: { selectedTabItems },
            } = await getSearchedData(requestParams, {
              ...(selectedFilter && { filters: selectedFilter }),
              ...(selectedSort && { sortby: selectedSort }),
            });

            if (selectedTabItems.length > 0) {
              selectedTabItems[0]?.filters &&
                setDisplayCategory(
                  selectedTabItems[0]?.filters[0]?.displayName
                );

              selectedTabItems[0]?.filters &&
                (selectedTabItems[0]?.filters[0]?.facets
                  ? setSubCategory(
                    selectedTabItems[0]?.filters[0]?.facets?.map(
                      (item: any) => item?.name
                    )
                  )
                  : setSubCategory(selectedTabItems[0]?.filters[0]?.range));
              selectedTabItems[0]?.filters &&
                setFilters(selectedTabItems[0]?.filters);
              setFilteredData(
                searchPage === "shopping"
                  ? [...filteredData, ...selectedTabItems[0].products]
                  : [...filteredData, ...selectedTabItems[0].deals]
              );
              setDisableInfiniteScroll(
                searchPage === "shopping"
                  ? selectedTabItems[0].products.length < 10
                  : selectedTabItems[0].deals.length < 10
              );
            } else {
              if (filteredData.length <= 0) {
                setFilteredData([{ noData: true }]);
              } else {
                setDisableInfiniteScroll(true);
              }
            }
          }
        } catch (err) {
          setFilteredData([{ noData: true }]);
        }
        setShowLoading(false);
      })();
    } else {
      (async () => {
        if (page === 1) {
          setShowLoading(true);
        }
        try {
          if (searchTerm !== "") {
            const {
              data: { selectedTabItems },
            } = await getSearchedData(requestParams, {
              ...(selectedFilter && { filters: selectedFilter }),
              ...(selectedSort && { sortby: selectedSort }),
            });

            if (selectedTabItems.length > 0) {
              selectedTabItems[0]?.filters &&
                setDisplayCategory(
                  selectedTabItems[0]?.filters[0]?.displayName
                );

              selectedTabItems[0]?.filters &&
                (selectedTabItems[0]?.filters[0]?.facets
                  ? setSubCategory(
                    selectedTabItems[0]?.filters[0]?.facets?.map(
                      (item: any) => item?.name
                    )
                  )
                  : setSubCategory(selectedTabItems[0]?.filters[0]?.range));
              selectedTabItems[0]?.filters &&
                setFilters(selectedTabItems[0]?.filters);
              setFilteredData(
                searchPage === "shopping"
                  ? [...filteredData, ...selectedTabItems[0].products]
                  : [...filteredData, ...selectedTabItems[0].deals]
              );
              setDisableInfiniteScroll(
                searchPage === "shopping"
                  ? selectedTabItems[0].products.length < 10
                  : selectedTabItems[0].deals.length < 10
              );
            } else {
              if (filteredData.length <= 0) {
                setFilteredData([{ noData: true }]);
              } else {
                setDisableInfiniteScroll(true);
              }
            }
          }
        } catch (err) {
          setFilteredData([{ noData: true }]);
        }
        setShowLoading(false);
      })();
    }
  }, [page, selectedFilter, selectedSort, searchTerm]);

  const loadNextPage = (e: any) => {
    setTimeout(() => {
      e.target.complete();
    }, 1000);
    setPage(() => page + 1);
  };

  const searchHandler = (event: any) => {
    if (event.key === "Enter" && event.target.value !== "") {
      history.replace({ search: `q=${encodeURIComponent(event.target.value)}` });
      updateSearchTerm(event);
    } else if (event.key === "Enter" && event.target.value === "") {
      history.goBack();
    }
  };

  const updateSearchTerm = (event: any) => {
    if (event.target.value !== searchTerm) {
      setFilteredData([]);
    }
    setSearchTerm(event.target.value);
    setPage(1);
    setDisableInfiniteScroll(false);
    googleAnalyticsSearchTerm(event.target.value, history.location.pathname.split('/')?.[1]);
  };

  const getCategory = (category: any) => {
    setDisplayCategory(category.displayName);
    if (!category.facets) {
      setSubCategory(category.range);
    } else {
      setSubCategory(category?.facets?.map((item: any) => item?.name));
    }
  };
  const getCheckedItem = (event: any, item: any) => {
    if (Object.keys(appliedFilter).length === 0) {
      setAppliedFilter({ [displayCategory]: [item] });
    } else {
      if (appliedFilter[displayCategory]) {
        if (event.target.checked) {
          setAppliedFilter({
            ...appliedFilter,
            [displayCategory]: [...appliedFilter[displayCategory], item],
          });
        } else {
          setAppliedFilter({
            ...appliedFilter,
            [displayCategory]: appliedFilter[displayCategory].filter(
              (filterItem: any) => filterItem !== item
            ),
          });
        }
      } else {
        setAppliedFilter({ ...appliedFilter, [displayCategory]: [item] });
      }
    }
  };

  const isChecked = (item: any) => {
    if (Object.keys(appliedFilter).length !== 0) {
      if (appliedFilter[displayCategory]) {
        return appliedFilter[displayCategory].includes(item);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const applyFilter = () => {
    const removeEmptyArray = Object.entries(appliedFilter).filter(
      (item: any) => item[1].length !== 0
    );
    if (removeEmptyArray.length !== 0) {
      setPage(1);
      setFilteredData([]);
      const selectedFilterObject = removeEmptyArray.reduce(
        (prev: any, item: any) => ({
          ...prev,
          [item[0]]: item?.[1]?.map((mapItem: any) => mapItem).join(","),
        }),
        {}
      );
      setSelectedFilter(selectedFilterObject);

      setAppliedFilter(
        removeEmptyArray.reduce(
          (prev: any, item: any) => ({
            ...prev,
            [item[0]]: item?.[1]?.map((mapItem: any) => mapItem),
          }),
          {}
        )
      );

      setPage(1);
    } else if (
      !selectedFilter &&
      Object.entries(appliedFilter).keys.length === 0 &&
      removeEmptyArray.length === 0
    ) {
    } else {
      setFilteredData([]);
      setAppliedFilter({});
      setSelectedFilter(undefined);
      setPage(1);
    }
    closeModal();
  };
  const resetFilter = () => {
    if (!selectedFilter && Object.entries(appliedFilter).keys.length === 0) {
    } else {
      setSelectedFilter(undefined);
      setAppliedFilter({});
      setFilteredData([]);
    }
    closeModal();
  };
  const applySort = (item: any) => {
    if (item !== selectedSort) {
      setPage(1);
      setSelectedSort(item);
      setFilteredData([]);
    }
  };
  const resetSort = (item: any) => {
    setPage(1);
    setSelectedSort(item);
    if (selectedSort) {
      setFilteredData([]);
    }
  };

  function closeModal() {
    modal.current?.dismiss();
  }
  function closeActionSheet() {
    dismiss();
  }
  const [present, dismiss] = useIonActionSheet();
  return (
    <IonPage className={styles.search}>
      <Header searchBar={true} />
      <IonHeader className="ion-no-padding ion-no-border ion-hide-sm-up">
        <IonToolbar className="header-primary-bg-color header-text-color">
          <IonGrid fixed className="ion-no-padding">
            <IonRow>
              <IonButtons className={`${styles.btnContainer}`}>
                <IonBackButton
                  className="ion-no-padding"
                  icon={chevronBack}
                  defaultHref={`/${searchPage}`}
                ></IonBackButton>
              </IonButtons>
              <IonTitle
                className={`${styles.dealDetailTitle} ion-no-padding ion-text-center`}
              >
                {searchPage.charAt(0).toUpperCase() + searchPage.slice(1)}
              </IonTitle>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="scrollableDesign ion-hide-sm-up">
          <div className="scrollable"></div>
        </div>
        <IonGrid fixed className={styles.content}>
          <IonRow class='ion-hide-md-up'>
            <div className={`${styles.searchBarContainer}`}>
              <IonSearchbar
                showCancelButton="always"
                onIonCancel={() => history.goBack()}
                placeholder="Search for Products,Brand and More"
                value={searchParam}
                onKeyPress={(event: any) => searchHandler(event)}
              ></IonSearchbar>
            </div>
          </IonRow>
          <IonRow class="ion-hide-md-up">
            <IonCol size="6">
              <IonButton
                expand="block"
                className={`ion-no-margin ${selectedSort ? 'primary-btn' : 'white-btn'
                  }`}
                onClick={() =>
                  present({
                    header: "Sort By",
                    cssClass: ["custom-sheet"],
                    buttons: [
                      {
                        text: "Relevance",
                        role: "destructive",
                        handler() {
                          resetSort(undefined);
                        },
                      },
                      {
                        text: "Popular",
                        role: `${selectedSort === "popular" && "selected"}`,
                        handler: () => {
                          applySort("popular");
                        },
                      },
                      {
                        text: "Price Low",
                        role: `${selectedSort === "pricelow" && "selected"}`,
                        handler: () => {
                          applySort("pricelow");
                        },
                      },
                      {
                        text: "Price High",
                        role: `${selectedSort === "pricehigh" && "selected"}`,
                        handler: () => {
                          applySort("pricehigh");
                        },
                      },
                      {
                        text: "Discount Low",
                        role: `${selectedSort === "discountlow" && "selected"}`,
                        handler: () => {
                          applySort("discountlow");
                        },
                      },
                      {
                        text: "Discount High",
                        role: `${selectedSort === "discounthigh" && "selected"
                          }`,
                        handler: () => {
                          applySort("discounthigh");
                        },
                      },
                    ],
                  })
                }
              >
                <IonIcon slot="start" icon={swapVerticalOutline} />
                Sort
              </IonButton>
            </IonCol>

            {/* FILTER */}
            <IonCol size="6">
              <IonButton
                id="open-modal"
                expand="block"
                className={`ion-no-margin ${selectedFilter ? 'primary-btn' : 'white-btn'
                  }`}
                disabled={filters.length === 0}
              >
                <IonIcon slot="start" icon={filterOutline} />
                Filter
              </IonButton>

              <IonModal
                trigger="open-modal"
                initialBreakpoint={1}
                breakpoints={[1]}
                ref={modal}
                handleBehavior="cycle"
              >
                <IonContent
                  className={`ion-bg-white ${styles.filterModal}`}
                  scrollY={true}
                >
                  <IonItem class={styles.filterHeader}>
                    <p className="ion-padding-start font14 font500 primary-title-color">
                      All Filters
                    </p>
                    <IonIcon
                      slot="end"
                      className="dark"
                      icon={close}
                      onClick={() => closeModal()}
                    />
                  </IonItem>
                  <IonGrid className="ion-no-padding">
                    <IonRow className="ion-no-padding">
                      <IonCol
                        className={`ion-no-padding ${styles.subCategory}`}
                        size="5"
                      >
                        {filters?.map((item: any, index: any) => (
                          <IonList
                            key={index}
                            className="ion-no-padding ion-no-border"
                          >
                            <IonItem
                              className={`ion-no-padding ${styles.filterCategoryItem}`}
                              onClick={() => getCategory(item)}
                            >
                              <IonButton
                                className={`ion-no-margin ${item.displayName === displayCategory ? 'white-btn' : 'tertiary-btn'}`}
                              >
                                <IonLabel className="font12 font500 padl10">
                                  {item.displayName}
                                </IonLabel>
                              </IonButton>
                            </IonItem>
                          </IonList>
                        ))}
                      </IonCol>
                      <IonCol
                        className={`ion-no-padding ${styles.filterSubcategory}`}
                        size="7"
                      >
                        {subCategory?.map((items: any, index: any) => (
                          <IonList key={index} className="ion-no-padding">
                            <IonItem className="ion-bg-white ion-no-padding">
                              <IonCheckbox
                                mode="ios"
                                checked={isChecked(items)}
                                className="ion-margin-start checkbox"
                                onClick={(event) =>
                                  getCheckedItem(event, items)
                                }
                              />
                              <IonLabel
                                className={`marl4 font12 ion-text-capitalize ${isChecked(items) ? "font500" : "font400"
                                  } ${styles.subCategoryLabel}`}
                              >
                                {items}
                              </IonLabel>
                            </IonItem>
                          </IonList>
                        ))}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonContent>
                <IonFooter>
                  <IonButton
                    className={`ion-no-margin white-btn font14 font500`}
                    expand="block"
                    onClick={() => resetFilter()}
                  >
                    Reset
                  </IonButton>
                  <IonButton
                    className={`ion-no-margin primary-btn font14 font500`}
                    expand="block"
                    onClick={() => applyFilter()}
                  >
                    Apply
                  </IonButton>
                </IonFooter>
              </IonModal>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonItem lines="none" className={`${styles.title} ion-hide-sm-up`}>
                <IonLabel className="font900">
                  {searchPage.charAt(0).toUpperCase() + searchPage.slice(1)}
                </IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
{
              !isMobileResolution && filters.length !== 0 && <IonCol sizeMd="3.2" sizeLg="2.5" class="ion-hide-md-down">
                <SortFilter data={filters} appliedFilter={appliedFilter} appliedSort={selectedSort} />
              </IonCol>
            }
            <IonGrid className={ isMobileResolution ? styles.contentGridMobile : styles.contentGrid}>
              <IonRow>
            {filteredData &&
              filteredData?.map((item: any, index: number) => {
                if (item.noData !== true) {
                  switch (searchPage) {
                    case "deals":
                      return (
                        <>
                              <IonCol size="6" sizeSm="4" sizeMd="4" key={index} className="ion-no-padding card_pad8 card_pad5">
                          <DealListCard dealDetail={item} />
                        </IonCol>
</>
                      );
                    case "shopping":
                      return (
                        <IonCol size="6" sizeSm="4" sizeMd="4" key={index} className="ion-no-padding">
                          <ProductGridItem item={item} />
                        </IonCol>
                      );
case "coupons":
                          return (
                            <IonCol size="6" sizeSm="4" sizeMd="4" key={index} className="ion-no-padding">
                              <CouponListCard coupon={item} />
                            </IonCol>
                          );
                  }
                } else {
                  return <NoData props={"No Results Found !"} key={index} />;
                }
              })}
          </IonRow>
</IonGrid>
          </IonRow>
          {
            filteredData && !filteredData?.[0]?.noData &&
          <IonInfiniteScroll
            onIonInfinite={(e) => loadNextPage(e)}
            threshold="100px"
            disabled={disableInfiniteScroll}
          // key={index}
          >
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
}
        </IonGrid>
        {filteredData.length > 0 && disableInfiniteScroll && <Account isHomePage={false}/>}
      </IonContent>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Please wait..."}
        duration={3000}
      />
    </IonPage>
  );
};
