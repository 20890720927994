/* eslint-disable react-hooks/rules-of-hooks */
import { IonLoading, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useUser } from "../../../Contexts/UserContext";
import { ssoLogin } from "../../../utils/getApiData";
import { usePartner } from "../../../Contexts/PartnerContext";
import { ssoPids } from "../../../utils/pidHelper";

export const SsoLogin = () => {
  const { usersession }: { usersession: string } = useParams();
  const { temptoken }: { temptoken: string } = useParams();
  const user = useUser();
  const history = useHistory();
  const { partnerPid, clientData } = usePartner();
  const [showLoading, setShowLoading] = useState(true);

  const bomSsoLogin = async (usersession: any, temptoken: any) => {
    const params = {
      pid: partnerPid,
    };
    const payload = {
      usersession: usersession,
      temptoken: temptoken,
    };
    setShowLoading(true);
    try {
      const { data } = await ssoLogin(params, payload);
      if (data?.token) {
        localStorage.setItem("usersession", usersession);
        localStorage.setItem("temptoken", temptoken);
        localStorage.setItem("profile", JSON.stringify(data));
        user?.setProfileId(data?.profileid);
        localStorage.setItem("userId", data?.profileid);
        localStorage.setItem("availUserPts", data?.availableUserPoints);
        history.replace(`/shopping`);
      } else {
        window.location.href = clientData?.redirectUrl;
      }
    } catch (err) {
      console.log(err);
      if (clientData?.redirectUrl) {
        window.location.href = clientData?.redirectUrl;
      }
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (ssoPids.includes(partnerPid)) {
      bomSsoLogin(usersession, temptoken);
    } else if (partnerPid && !ssoPids.includes(partnerPid)) {
      history.replace(`/shopping`);
    }
  }, [partnerPid]);

  return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        message={"Logging to your account ..."}
      />
    </IonPage>
  );
};
