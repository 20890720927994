import React from "react";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonText,
  IonBackButton,
  IonRow,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { Account } from "../../../components/Account/Account";

export const AboutUsUk: React.FC = () => {
  return (
    <IonPage>
      <IonHeader className="ion-padding-horizontal ion-hide-sm-down">
        <IonGrid fixed>
          <IonToolbar>
            <IonTitle className="ion-no-padding font18 font600">
              About Us
            </IonTitle>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonHeader className="ion-hide-sm-up">
        <IonGrid fixed>
          <IonToolbar>
            <IonRow>
              <IonBackButton
                className="ion-no-padding"
                icon={chevronBack}
              ></IonBackButton>
              <IonTitle className="ion-padding-horizontal font18 font600">
                About Us
              </IonTitle>
            </IonRow>
          </IonToolbar>
        </IonGrid>
      </IonHeader>
      <IonContent>
        <IonGrid fixed className="h90 ion-padding-horizontal">
          <IonText>
            <h1 className="font18 font600">
              Welcome to Scandid - Your Ultimate Online Shopping Destination in
              the UK
            </h1>
            <p className="font14 font400">
              At Scandid, we revolutionize the way you shop online by offering a
              convenient, hassle-free, and highly rewarding experience. Our
              platform is designed to help you find your desired products at the
              best possible prices, ensuring that you save money while enjoying
              the benefits of smart, reliable shopping.
            </p>

            <h2 className="font16 font600">Unbeatable Best Price Shopping</h2>
            <p className="font14 font400">
              In today's competitive online marketplace, the same product can
              have different prices across various platforms. Scandid aggregates
              pricing from all major UK online shopping sites to ensure you
              always get the best deal. Our advanced price comparison tools and
              AI-powered deal discovery engine work around the clock, analyzing
              thousands of offers so that you never overpay again. Enjoy the
              peace of mind that comes with knowing you're always securing the
              best price on electronics, fashion, home goods, and more.
            </p>

            <h2 className="font16 font600">
              Reliable and Trustworthy Shopping Experience
            </h2>
            <p className="font14 font400">
              Shopping online should be as reliable as it is convenient. At
              Scandid, we are committed to recommending only the most
              trustworthy merchants. We partner with reputable online retailers
              who guarantee that all products are brand new and come with the
              standard manufacturer warranty. Our stringent vetting process
              ensures that you can shop with complete confidence, knowing that
              every purchase is secure and backed by industry-leading customer
              service.
            </p>

            <h2 className="font16 font600">
              Smart Shopping for Informed Decisions
            </h2>
            <p className="font14 font400">
              With a flood of choices available at your fingertips, finding the
              right product that matches your budget and needs can be
              overwhelming. Scandid simplifies your decision-making process by
              offering clear, comprehensive comparisons and detailed product
              insights. Our smart shopping tools are tailored to empower you
              with the information you need, ensuring that every purchase is an
              informed decision. Whether you're searching for the latest tech
              gadgets, stylish apparel, or home essentials, our platform guides
              you towards the best options available in the market.
            </p>

            <h2 className="font16 font600">
              Experience the Future of Online Shopping
            </h2>
            <p className="font14 font400">
              Scandid isn't just a price comparison tool—it's your trusted
              partner in the online shopping journey. Our platform provides a
              seamless and enjoyable shopping experience that saves you time and
              money. By integrating with top UK e-commerce sites, we offer a
              robust, user-friendly interface where millions of savvy shoppers
              discover and benefit from exclusive deals and promotions every
              day.
            </p>

            <p className="font14 font400">
              Our commitment to transparency, quality, and customer satisfaction
              sets us apart from traditional online retailers. With our
              state-of-the-art deal discovery engine, real-time insights, and
              continuously expanding network of reliable merchants, Scandid is
              paving the way for a smarter, safer, and more convenient online
              shopping experience in the UK.
            </p>

            <p className="font14 font400">
              Visit us at the{" "}
              <a
                href="https://uk.scandid.deals/"
                target="_blank"
                rel="noreferrer"
                className="padl10 padr10"
              >
                uk.scandid.deals
              </a>
              to discover unbeatable deals across leading UK online retailers.
            </p>
          </IonText>
        </IonGrid>
        <Account isHomePage={false} />
      </IonContent>
    </IonPage>
  );
};
