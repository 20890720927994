import React from 'react';
import {
  IonModal,
  IonContent,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from '@ionic/react';
import styles from "./RedirectAskModal.module.scss";

interface RedirectAskModalProps {
  isOpen: boolean;
  onClose: (isRedirected: boolean) => void;
}

export const RedirectAskModal: React.FC<RedirectAskModalProps> = ({ isOpen, onClose}) => {
  const onClickButton = () => {
    onClose(true);
  };
  
  return (
    <IonModal 
      isOpen={isOpen} 
      onDidDismiss={()=>{onClose(false)}}
      className={styles.RedirectAskModal}
    
    >
      <IonContent>
        <IonCard className={styles.modalCard}>
          <IonCardHeader className="no-padding-bottom">
            <IonCardTitle className={`${styles.modalTitle} card-title-color`}>
              This Offer is exclusive for Bank of Maharashtra customers and only valid on the usage of Bank of Maharashtra Card
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="no-padding-bottom">
            <IonButton 
              expand="block"
              className={`secondary-btn`}
              onClick={onClickButton}
            >
              Proceed
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonModal>
  )
};
