export const cashbackColumns = {
  ccbom: {
    date: true,
    merchant: true,
    saleAmount: true,
    estimate: true,
    status: false,
  },
};

export const cashbackAmountPoints = (isPoints: boolean, amount: any) => {
  if (isPoints) {
    return amount ? amount : "0";
  }
  return amount ? `Rs. ${amount?.toFixed(2)}` : "Rs. 0.0";
};
